import { Injectable, Injector } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from 'src/shared/services/auth.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor(private _authService: AuthService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add authorization header with jwt token if available
        // const currentUser = JSON.parse(localStorage.getItem('currentUser'));
        const currentUser = this._authService.currentUserValue;
        if (currentUser && currentUser.token) {
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${currentUser.token}`
                }
            });
        }

        return next.handle(request);

        // From Angular Authentication Tutorial
        // const authService = this._injector.get(AuthService);

        // const tokenizedReq = request.clone({
        //     setHeaders: {
        //         Authorization: `Bearer ${authService.getToken()}`
        //     }
        // });

        // From Angular Authentication Tutorial
        // return next.handle(request);
    }
}
