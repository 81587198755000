// Interfaces
import { ISelectable } from 'src/shared/interfaces';

export class Status implements ISelectable {
    id: number;
    name: string;
    description: string;
    isActiveStatus: boolean;

    // ISelectable
    selected: boolean;

    constructor(obj: any = {}) {
        this.id = obj.id || '';
        this.name = obj.name || '';
        this.description = obj.description || '';
        this.isActiveStatus = obj.isActiveStatus || true;

        this.selected = obj.selected || false;

        return this;
    } // constructor ()


    /// addStatus()
    /// returns:
    ///     message: string
    ///     success: boolean
    addStatus() {
        // API AddStatus call
        return { message: 'Status successfully created.', success: true };
    } // addStatus()


    /// deleteStatus()
    /// returns:
    ///     message: string
    ///     success: boolean
    deleteStatus() {
        // API DeleteStatus call
        return { message: 'Status successfully deleted.', success: true };
    } // deleteStatus()


    /// updateStatus()
    /// returns:
    ///     message: string
    ///     success: boolean
    updateStatus() {
        // API UpdateStatus call
        return { message: 'Status successfully updated.', success: true };
    } // updateStatus()
}
