// Angular Core
import { Component } from '@angular/core';
import { Router } from '@angular/router';

// Services
import { CartService, UserService, AuthService } from 'src/shared/services';
// Models
import { Cart, CartItem, CartItemStatus, User } from 'src/shared/models';


@Component({
    selector: 'app-payment',
    templateUrl: './payment.component.html',
    styleUrls: ['./payment.component.scss']
})

export class PaymentComponent {

    currentUser: User;
    cart: Cart;

    constructor (
        private authService: AuthService,
        private cartService: CartService,
        private router: Router
    ) {
        this.authService.currentUser.subscribe(x => this.currentUser = x);
        this.cartService.currentCart.subscribe(x => this.cart = x);
    }

    // ngOnInit () {
    // }


    testRedirect() {
        this.router.navigate(['/cart/order-successful']);
    }

}
