export class Personalized {
    id: number;
    name: string;
    existingName: string;

    constructor(obj: any) {
        if (!obj) {
            return this;
        }
        this.id = obj.id ? obj.id : 0;
        this.name = obj.name ? obj.name : '';
        this.existingName = obj.existingName ? obj.existingName : '';

        return this;
    }
}
