// Models
import { Role } from './role.model';
// Interfaces
import { IPagination, ISort } from 'src/shared/interfaces';


export class Roles implements IPagination, ISort {
    roles: Array<Role>;
    // IPagination
    limit: number;
    page: number;
    pages: number;
    total: number;
    // ISort
    sort: string;
    sortDir: string;

    constructor(obj: any = {}) {
        this.roles = obj.roles && obj.roles.length
            ? obj.roles.map((role: Role) => new Role(role))
            : new Array<Role>();

        if (obj.roles && obj.roles.length) {
            obj.roles.map((role: Role) => {
                this[role.name] = new Role(role);
            });
        }
        // IPagination
        this.limit  = parseInt(obj.limit, 10)   || 10;
        this.page   = parseInt(obj.page, 10)    || 1;
        this.pages  = parseInt(obj.pages, 10)   || 1;
        this.total  = parseInt(obj.total, 10)   || 0;
        // ISort
        this.sort = obj.sort || '';
        this.sortDir = obj.sortDir || 'ASC';

        return this;
    }

}
