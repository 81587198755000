// Models
import { Product } from './product.model';
import { Event } from './event.model';
import { Status } from './status.model';
// Interfaces
import { ISelectable } from 'src/shared/interfaces';


export class ProductGroup implements ISelectable {
    id: number;
    name: string;
    description: string;
    note: string;
    productModel: string;
    products: Array<any>;
    status: Status;
    // ISelectable
    selected: boolean;

    constructor( productGroup: any = {} ) {
        this.id = productGroup.id || '';
        this.name = productGroup.name || '';
        this.description = productGroup.description || '';
        this.note = productGroup.note || '';
        this.productModel = productGroup.productModel || '';
        if ((productGroup.products && productGroup.products.length)) { // && productGroup.productModel === 'Product')
            this.products = productGroup.products.map(product => new Product(product));
        } else if ((productGroup.events && productGroup.events.length)) { // && productGroup.productModel === 'Event')
            this.products = productGroup.events.map(event => new Event(event));
        } else { this.products = new Array<Product>(); }
        this.status = productGroup.status || new Status();


        return this;
    }
}
