// Models
import { ProductOption } from './product-option.model';
// Interfaces
import { IPagination } from 'src/shared/interfaces';


export class ProductOptions implements IPagination {
    productOptions: Array<ProductOption>;
    // IPagination
    limit: number;
    page: number;
    pages: number;
    total: number;

    constructor(obj: any = {}) {
        this.productOptions = obj.productOptions && obj.productOptions.length
            ? obj.productOptions.map((option) => {
                return new ProductOption(option);
            })
            : new Array<ProductOption>();
        // IPagination
        this.limit  = parseInt(obj.limit, 10)   || 10;
        this.page   = parseInt(obj.page, 10)    || 1;
        this.pages  = parseInt(obj.pages, 10)   || 1;
        this.total  = parseInt(obj.total, 10)   || 0;

        return this;
    } // constructor ()

}
