import { Component, OnInit } from '@angular/core';
import { OrderService } from 'src/shared/services';
import { OrderReturn } from 'src/shared/models';

@Component({
  selector: 'app-order-successful',
  templateUrl: './order-successful.component.html',
  styleUrls: ['./order-successful.component.scss']
})
export class OrderSuccessfulComponent implements OnInit {

  orderReturn: OrderReturn;

  constructor(private orderService: OrderService) { 
    this.orderService.currentOrderReturn.subscribe(x => this.orderReturn  = x);
  }

  ngOnInit() {
  }

}
