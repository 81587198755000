// Models
import { ProductGroup } from './product-group.model';
import { EventOccurrence } from './event-occurrence.model';
import { Status } from './status.model';
// Interfaces
import { ISelectable } from 'src/shared/interfaces';
// Services
import { DataService } from 'src/shared/services';


export class Event implements ISelectable {
    id: number;
    name: string;
    description: string;
    note: string;
    seatsAvailable: number;
    price: number;
    taxable: boolean; // Update to taxable, non-taxable, or tax-included
    productModel: string;
    productGroup: string;
    productOptions: Array<EventOccurrence>;
    eventOccurrenceDates: Array<String>;
    status: Status;
    // ISelectable
    selected: boolean;

    constructor(obj: any = { }) {
        this.id = obj.id || '';
        this.name = obj.name || '';
        this.description = obj.description || '';
        this.note = obj.note || '';
        this.seatsAvailable = obj.seatsAvailable || 0;
        this.price = obj.price || 0;
        this.taxable = (typeof obj.taxable !== 'undefined') ? !!obj.taxable : true;
        this.productModel = obj.productModel || 'Event';
        this.productGroup = obj.productGroup
            ? obj.productGroup.id
            : '';
        this.productOptions = obj.productOptions
            ? obj.productOptions.map(productOption => new EventOccurrence(productOption))
            : obj.eventOccurrences
                ? obj.eventOccurrences.map(eventOccurrence => new EventOccurrence(eventOccurrence))
                : new Array<EventOccurrence>()
            || new Array<EventOccurrence>();
        this.eventOccurrenceDates = obj.eventOccurrenceDates || new Array<String>();
        this.status = obj.status || new Status();
        // ISelectable
        this.selected = obj.selected || false;

        return this;
    } // constructor ()
}
