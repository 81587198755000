// Models

// Interfaces

export class ReservationType {
    id: number;
    type: string;
    price: number;
    extendedPrice: number;
    pricePerCamper: boolean;
    deposit: number;
    availableOptions: Array<string>;
}
