
export class PaymentCard {
    creditCardNumber: string;
    name: string;
    // expiration: string;
    expirationMonth: string;
    expirationYear: string;
    cvd: string;
    billingZip: string;
    webTransaction: true;

    // public setExpiration() {
    //     this.expiration = this.expirationMonth + this.expirationYear.substr(2, 2);
    // }
}
