import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, of, throwError } from 'rxjs';
import 'rxjs/add/operator/catch';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { map, catchError, tap, retry } from 'rxjs/operators';
import { Response, Request } from '@angular/http';

// Models
import { Order, Orders, OrderReturn } from 'src/shared/models';
import { PaymentCard } from 'src/shared/models/payment-card.model';


// Local Variables
import { environment } from 'src/environments/environment';

const apiPath = environment.apiPrefix + 'api/v1/order';
const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type':  'application/json' })
};
@Injectable({ providedIn: 'root' })

export class OrderService {
    private currentOrderSubject: BehaviorSubject<Order>;
    public currentOrder: Observable<Order>;

    private currentOrderReturnSubject: BehaviorSubject<OrderReturn>;
    public currentOrderReturn: Observable<OrderReturn>;

    constructor(private _http: HttpClient) {
        this.currentOrderSubject = new BehaviorSubject<Order>(new Order(JSON.parse(localStorage.getItem('Order'))));
        this.currentOrder = this.currentOrderSubject.asObservable() as Observable<Order>;

        this.currentOrderReturnSubject = new BehaviorSubject<OrderReturn>(new OrderReturn());
        this.currentOrderReturn = this.currentOrderReturnSubject.asObservable() as Observable<OrderReturn>;
    }

    // Observable Subject
    // private _OrderSubject = new Subject<Order>();
    // OrderItems:  OrderItem[] = [];
    // Order = this._OrderSubject.asObservable();


    // addProduct(_product: any) {
    //     console.log('in service');
    //     this.OrderItems.push(_product);
    //     this._OrderSubject.next(<Order> { loaded: true, OrderItems: this.OrderItems });
    // }

    // removeProduct(id: number) {
    //     this.OrderItems = this.OrderItems.filter((_item) => _item.id !== id);
    //     this._OrderSubject.next(<Order> { loaded: false, OrderItems: this.OrderItems });
    // }



  // HTTP REQUESTS

    // Get all (or filtered/sorted) users from the server
    // getUsers(users: Users = new Users()): Observable<Users[]> {

    //     const params: HttpParams = new HttpParams()
    //         .set('page', users.page.toString())
    //         .set('limit', users.limit.toString());

    //     return this._http.get(`${apiPath}`, { params: params })
    //         .pipe(
    //             map(res => res['data']),
    //             retry(3), // retry a failed request up to 3 times
    //             catchError(this.handleError)
    //         );
    // }

    // Get single user (by id) from the server
    // getUserById(id): Observable<User> {
    //     if (!id || id === '') { return null; }
    //     return this._http.get(`${apiPath}/id/${id}`)
    //         .pipe(
    //             map(res => {
    //                 console.log(res);
    //                 return res['data'];
    //             }),
    //             retry(3),
    //             catchError(this.handleError)
    //         );
    // }

    setOrder(order: Order) {
        localStorage.setItem('currentOrder', JSON.stringify(order));
        this.currentOrderSubject.next(order);
    }

    setOrderReturn(orderReturn: OrderReturn) {
        // No need to store the order return value.
        this.currentOrderReturnSubject.next(orderReturn);
    }


    clearOrder() {
        localStorage.removeItem('currentOrder');
        this.currentOrderSubject.next(new Order());
    }

    // Get Order by email
    // getOrdersByUser(id: number): Observable<Orders> {
    //     return this._http.get(`${apiPath}/user/${id}`)
    //     .pipe(
    //         retry(3),
    //         catchError(this.handleError),
    //         map(result => {
    //             this.setOrder(new Orders(result['data']));
    //             return result['data'];
    //         })
    //     );
    // }

    // Get Order by order id
    getOrderById(id: number): Observable<Order> {
        if (!id || id <= 0) { return null; }
        return this._http.get(`${apiPath}/${id}`)
        .pipe(
            retry(3),
            catchError(this.handleError),
            map(result => {
                this.setOrder(new Order(result['data']));
                return result['data'];
            })
        );
    }

    placeOrder(cartId: number, paymentCard: PaymentCard): Observable<OrderReturn> {
        if (!cartId || cartId <= 0) { return null; }
        return this._http.post(`${apiPath}/place/${cartId}`, paymentCard)
        .pipe(
            // retry(3),
            catchError(this.handleError),
            map(result => {
                // this.setOrder(new OrderReturn(result['data']));
                this.setOrderReturn(new OrderReturn(result['data']));
                return result['data'];
            })
        );
    }

    getOrdersByUserId(id: number): Observable<Orders> {
        if (id <= 0) { return null; }
        return this._http.get(`${apiPath}/user/${id}`)
        .pipe(
            catchError(this.handleError),
            map( result => {
                return new Orders(result['data']);
            })
        )
    }

    // Update a Order
    updateOrder(Order): Observable<Order> {
        return this._http.put(`${apiPath}/${Order.id}`, Order)
            .pipe(
                // retry(3),
                catchError(this.handleError),
                map(result => {
                    this.setOrder(new Order(result['data']));
                    return result['data'];
                })
            );
    }

    // Add an item to a Order
    addItemToOrder(OrderItem): Observable<Order> {
        return this._http.post(`${apiPath}/items`, OrderItem)
            .pipe(
                catchError(this.handleError),
                map(result => {
                    this.setOrder(new Order(result['data']));
                    return result['data'];
                })
            );
    }

  // ERROR HANDLING
    private handleError(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.error(
                `Backend returned code ${error.status}, ` +
                `body was: ${error.error}`
            );
        }
        // return an observable with a user-facing error message
        return throwError('Something bad happened; please try again later.');
    }

}
