import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { filter } from 'rxjs/operators';
import { AuthService } from 'src/shared/services';

@Component({
  selector: 'app-pw-reset',
  templateUrl: './pw-reset.component.html',
  styleUrls: ['./pw-reset.component.scss']
})
export class PwResetComponent implements OnInit {

  emailAddress: string;
  token: string;
  newPassword: string;
  emailStep = true;
  passwordStep = false;
  finished = false;
  resultMessage: string;
  errorMessage: string;

  constructor(private authService: AuthService, private router: Router, private activatedRoute: ActivatedRoute) { }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {
      this.emailAddress = params['email'];
      this.token = params['token'];
      if (this.emailAddress && this.token) {
        this.emailStep = false;
        this.passwordStep = true;
      }
    });
  }

  sendResetEmail() {
    if (!this.emailAddress) {
      return;
    }
    this.authService.sendResetEmail(this.emailAddress).subscribe(
      result => {
        console.log(result);
        if (result.message) {
          this.emailStep = false;
          this.finished = true;
          this.resultMessage = result.message;
        }
      },
      error => {
        console.log(error);
        this.errorMessage = error;
      });
  }

  resetPassword() {
    if (!this.newPassword) {
      return;
    }
    this.authService.resetPassword({
      emailAddress: this.emailAddress,
      resetToken: this.token,
      newPassword: this.newPassword
    }).subscribe(
      result => {
        console.log(result);
        if (result.message) {
          this.passwordStep = false;
          this.finished = true;
          this.resultMessage = result.message;
        }
      }, error => {
        console.log(error);
        this.errorMessage = error;
      })
  }

  cancel() {
    this.router.navigate(['/home']);
  }

}
