import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { AuthService, RoleService, PageService } from 'src/shared/services';


@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
    // isLoggedIn$: Observable<boolean>;

    constructor(
        private authService: AuthService,
        private roleService: RoleService,
        private pageService: PageService,
        private router: Router
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        console.log('AuthGuard.canActivate() called');
        const currentUser = this.authService.currentUserValue;

        // If user is not logged in, redirect them to ''
        if (!currentUser) {
            this.router.navigate([''], { queryParams: {returnUrl: state.url} });
            return of(false);
        }

        // return of(true);
        return this.pageService.getPageByPath(route.data.path)
            .pipe(map(result => {

                if (result && result.roles && result.roles.length) {
                    // Check if user can access route
                    const userHasRole = this.authService.userHasRole(currentUser, result.roles);

                    // If user does not have role, redirect the back to /
                    if (!userHasRole) { this.router.navigate(['/']); }

                    // Return result
                    return userHasRole;
                } else {
                    console.log('Page has no roles');
                    // Page doesn't have any roles, therefore everyone is allowed
                    return true;
                }

            }));
    }
}
