import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// Required for Angular Material components to work
// import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// Required to use momentjs with Angular Material
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import {DragDropModule} from '@angular/cdk/drag-drop';

// Forms
import {
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatDatepickerModule,
    MatButtonModule,
    MatCheckboxModule
} from '@angular/material';

// Data/Tables
import {
    MatTableModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatSortModule
} from '@angular/material';


// Miscellaneous
import {
  MatExpansionModule,
  MatTabsModule,
  MatIconModule,
  MatDialogModule,
  MatDividerModule,
  MatRippleModule,
  MatBadgeModule
} from '@angular/material';


const modules = [
  // **Required
  CommonModule,
  // BrowserAnimationsModule,
  MatMomentDateModule,
  DragDropModule,
  // Forms
  MatFormFieldModule,
  MatInputModule,
  MatSelectModule,
  MatSlideToggleModule,
  MatDatepickerModule,
  MatButtonModule,
  MatCheckboxModule,
  // Data/Tables
  MatTableModule,
  MatPaginatorModule,
  MatProgressSpinnerModule,
  MatSortModule,
  // Miscellaneous
  MatExpansionModule,
  MatTabsModule,
  MatIconModule,
  MatDialogModule,
  MatDividerModule,
  MatRippleModule,
  MatBadgeModule
];

@NgModule({
  imports: [ modules ],
  exports: [ modules ],
})

export class AngularMaterialModule { }
