// Modules
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
// import { BrowserModule } from '@angular/platform-browser';
import { AngularMaterialModule } from '../shared/angular-material.module';

import { CartRoutingModule } from './cart-routing.module';

// Services
import { PaymentService } from 'src/shared/services/payment.service';


// Shared Components



// App Components
import { CartComponent } from './cart.component';
import { CartOverlayComponent } from './cart-overlay/cart-overlay.component';
import { CheckoutComponent } from './checkout/checkout.component';
import { PaymentComponent } from './payment/payment.component';
import { OrderSuccessfulComponent } from './order-successful/order-successful.component';
import { OrderFailedComponent } from './order-failed/order-failed.component';



@NgModule({
    declarations: [
        CartComponent,
        CartOverlayComponent,
        CheckoutComponent,
        PaymentComponent,
        OrderSuccessfulComponent,
        OrderFailedComponent
    ],
    imports: [
        FormsModule,
        // BrowserModule,
        AngularMaterialModule,
        CartRoutingModule
    ],
    exports: [
        CartOverlayComponent
    ],
    providers: [PaymentService],
    bootstrap: []
})
export class CartModule { }
