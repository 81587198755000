import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { map, catchError, tap, retry } from 'rxjs/operators';

// Models
import { Product } from 'src/shared/models';



// Local Variables
import { environment } from 'src/environments/environment';

const apiPath = environment.apiPrefix + 'api/v1/products';
const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type':  'application/json' })
};



@Injectable({
  providedIn: 'root'
})

export class ProductService {

    constructor(private _http: HttpClient) { }


  // HTTP REQUESTS


    // Get product by id
    getProductById(id: string): Observable<Product> {
        if (!id || id === '') { return null; }
        return this._http.get(`${apiPath}/${id}`)
        .pipe(
            retry(3),
            catchError(this.handleError),
            map(res => res['data'])
        );
    }


    // Get products by product group id

    // Update a product
    updateProduct(product): Observable<Product> {
        return this._http.put(`${apiPath}/${product.id}`, product)
            .pipe(
                // retry(3),
                catchError(this.handleError),
                map(res => res['data'])
            );
    }


  // ERROR HANDLING
    private handleError(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.error(
                `Backend returned code ${error.status}, ` +
                `body was: ${error.error}`
            );
        }
        // return an observable with a user-facing error message
        return throwError('Something bad happened; please try again later.');
    }

}
