// Models
import { Status } from './status.model';
// Interfaces
import { IPagination } from 'src/shared/interfaces';

export class Statuses implements IPagination {
    statuses: Array<Status>;
    // IPagination
    limit: number;
    page: number;
    pages: number;
    total: number;

    constructor(obj: any = {}) {
        this.statuses = obj.statuses && obj.statuses.length
            ? obj.statuses.map((status) => new Status(status))
            : new Array<Status>();
        // IPagination
        this.limit = obj.limit || 10;
        this.page = obj.page || 1;
        this.pages = obj.pages || 1;
        this.total = obj.total || 0;

        return this;
    } // constructor ()


    getStatuses() {
        return { message: 'Successfully retrieved statuses', success: true };
    }
}
