// Models
import { EventOccurrence } from './event-occurrence.model';
// Interfaces
import { IPagination } from 'src/shared/interfaces';


export class EventOccurrences implements IPagination {
    eventOccurrences: Array<EventOccurrence>;
    // IPagination
    limit: number;
    page: number;
    pages: number;
    total: number;

    constructor(obj: any = {}) {
        this.eventOccurrences = obj.eventOccurrences && obj.eventOccurrences.length
            ? obj.eventOccurrences.map((occurrence) => {
                return new EventOccurrence(occurrence);
            })
            : new Array<EventOccurrence>();
        // IPagination
        this.limit  = parseInt(obj.limit, 10)   || 10;
        this.page   = parseInt(obj.page, 10)    || 1;
        this.pages  = parseInt(obj.pages, 10)   || 1;
        this.total  = parseInt(obj.total, 10)   || 0;

        return this;
    } // constructor ()

}
