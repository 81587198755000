// Angular Core
import { Component, OnInit, OnChanges, SimpleChanges, EventEmitter, HostListener, Input, Output } from '@angular/core';

// Services
import { CartService, UserService, AuthService } from 'src/shared/services';
// Models
import { Cart, CartItem, CartItemStatus, User } from 'src/shared/models';

@Component({
    selector: 'app-cart-overlay',
    templateUrl: './cart-overlay.component.html',
    styleUrls: ['./cart-overlay.component.scss']
})

export class CartOverlayComponent implements OnInit {
    // @Input() cart: Cart;
    // @Output() cartChange = new EventEmitter();

    currentUser: User;
    cart: Cart;
    interval;
    startup = true;
    clickToggled = false;
    changeToggled = false;
    showNotification = false;
    loginEvent = false;

    @Input()
    set toggleCart(toggle: boolean) {
        if (toggle) {
            this.toggleMenu();
        }
    }

    constructor (
        private authService: AuthService,
        private cartService: CartService
    ) {
        this.cart = new Cart();
        this.authService.currentUser.subscribe(x => this.onLogin(x, this)); // this.currentUser = x);
        this.cartService.currentCart.subscribe(x => {
            console.log(x);
                this.cart = x;
                if (this.startup || this.loginEvent) {
                    this.startup = false;
                    this.loginEvent = false;
                    return;
                }
                this.toggleNotification();

        });
    }

    showCart = false;
    firstEventFired = false;


    onLogin(currentUser: User, cartOverlay: CartOverlayComponent) {
        cartOverlay.currentUser = currentUser;
        this.loginEvent = true;
        if (currentUser) {
            cartOverlay.getCartByUser(currentUser.id);
        }
    }

    ngOnInit () {
        if (this.currentUser) {
            this.getCartByUser(this.currentUser.id);
        } else {
            // this.cart = new Cart();
        }
    }

    // TODO: Fix cart opening on login.

    public toggleMenu() {
        this.showCart = !this.showCart;
        this.clickToggled = true;
    }

    toggleNotification() {
        clearInterval(this.interval);
        this.clickToggled = false;
        this.showCart = true;
        this.interval = setInterval(() => {
          this.showCart = false;
          clearInterval(this.interval);
        }, 3000);
      }

    clickedInside($event: Event) {
        $event.preventDefault();
        $event.stopPropagation();  // <- that will stop propagation on lower layers
    }

    @HostListener('document:click', ['$event']) clickedOutside($event) {
        if (this.showCart) {
            console.log('CLICKED OUTSIDE');
            this.showCart = false;
        }
    }


    getCartByUser(id: number) {
        if (this.currentUser && this.currentUser.id) {
            this.cartService.getCartByUser(id)
                .subscribe(
                    result => { this.cart = new Cart(result); },
                    error => console.log(error.message)
                );
        }
    }

    getCartById(id: number) {
        if (this.currentUser && this.currentUser.email) {
            this.cartService.getCartById(id)
                .subscribe(
                    result => this.cart = new Cart(result),
                    error => console.log(error.message)
                );
        }
    }

    removeItem(cartItem: CartItem) {
        if (this.cart && this.cart.id) {
            this.cartService.removeItemFromCart(cartItem.id)
                .subscribe(
                    result => {
                        console.log(result);
                    },
                    error => console.log(error.message)
                );
        } else {
            this.cart.cartItems.splice(this.cart.cartItems.indexOf(cartItem));
        }
    }

}
