export class Address {
    id: number;
    address1: string;
    address2: string;
    city: string;
    state: string;
    zipcode: string;

    constructor(
        id: number = -1,
        address1: string = '',
        address2: string = '',
        city: string = '',
        state: string = '',
        zipcode: string = ''
    ) {
        this.id = id || -1;
        this.address1 = address1;
        this.address2 = address2;
        this.city = city;
        this.state = state;
        this.zipcode = zipcode;

        return this;
    }
}
