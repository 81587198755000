import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Components
import { CartComponent              } from './cart.component';
import { CheckoutComponent          } from './checkout/checkout.component';
import { PaymentComponent           } from './payment/payment.component';
import { OrderSuccessfulComponent   } from './order-successful/order-successful.component';
import { OrderFailedComponent       } from './order-failed/order-failed.component';

const routes: Routes = [{
    path: '',
    component: CartComponent,
    children: [
        { path: '', component: CheckoutComponent },
        { path: 'payment', component: PaymentComponent },
        { path: 'order-successful', component: OrderSuccessfulComponent },
        { path: 'order-failed', component: OrderFailedComponent }
    ]
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CartRoutingModule { }
