// Models
import { Product } from './product.model';
import { Status } from './status.model';
// Interfaces
import { ISelectable } from 'src/shared/interfaces';
import { ProductDate } from './product-date.model';
import { ProductModifier } from './product-modifier.model';


export class ProductOption implements ISelectable {
    id: number;
    product: Product;
    productDate: ProductDate; // Can be null
    productModifier: ProductModifier; // Can be null
    name: string;
    description: string;
    note: string;
    price: number;
    status: Status;
    // ISelectable
    selected: boolean;

    constructor(obj: any = {}) {
        this.id = obj.id || '';
        this.product = obj.product || '';
        this.productDate = obj.ProductDate || '';
        this.productModifier = obj.ProductModifier || '';
        this.name = obj.name || '';
        this.description = obj.description || '';
        this.note = obj.note || '';
        this.price = obj.price || 0;
        this.status = obj.status || new Status();
        // ISelectable
        this.selected = obj.selected || false;

        return this;
    }
}
