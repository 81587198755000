import * as moment from 'moment';

// Models
import { OrderItem } from './order-item.model';
import { OrderStatus } from './order-status.model';
import { Discount } from './discount.model';
import { User } from './user.model';
import { Address } from './address.model';


export class Order {
    id: number;
    // Eventually add a more complex model to track changes of status, and when it changed, for sake of order history
    status: OrderStatus;
    orderedOn: string;
    userId: number;
    // user: User;
    billingAddress: Address;
    shippingAddress: Address;
    orderItems: Array<OrderItem>;
    totalQty: number;
    discounts: Array<Discount>;
    subTotal: number;
    tax: number;
    shipping: number; // May not be needed, but if so, needs to be an object (to cover ground, express, etc., and cost)
    total: number;

    constructor(obj: any = { }) {
        if (!obj) {
            return this;
        }
        this.id = obj.id || '';
        this.status = new OrderStatus(obj.status) || new OrderStatus();
        this.orderedOn = obj.orderDate || '?';
        this.userId = obj.userId || null;
        // this.user = new User(obj.user) || null;
        this.billingAddress = new Address(obj.billingAddress) || null;
        this.shippingAddress = new Address(obj.shippingAddress) || null;
        this.orderItems = obj.orderItems ? obj.orderItems.map((orderItem) => new OrderItem(orderItem)) : new Array<OrderItem>();
        this.discounts = obj.discounts ? obj.discounts.map((discount) => new Discount(discount)) : new Array<Discount>();
        this.subTotal = obj.subTotal || null;
        this.tax = obj.tax || null;
        this.shipping = obj.shipping || null;
        this.total = obj.total || null;
        if (this.orderItems && this.orderItems.length) {
            this.totalQty = this.orderItems.reduce((acc, orderItem) => acc += orderItem.qty, 0);
        }
        console.log('TotalQty: ' + this.totalQty);
        return this;
    }
}
