// Models
import { Order } from './order.model';
// Interfaces
import { IPagination } from 'src/shared/interfaces';


export class Orders implements IPagination {
    orders: Array<Order>;
    // IPagination
    limit: number;
    page: number;
    pages: number;
    total: number;

    constructor(obj: any = {}) {
        this.orders = obj && obj.length
            ? obj.map((order) => {
                return new Order(order);
            })
            : new Array<Order>();
        // IPagination
        this.limit  = parseInt(obj.limit, 10)   || 10;
        this.page   = parseInt(obj.page, 10)    || 1;
        this.pages  = parseInt(obj.pages, 10)   || 1;
        this.total  = parseInt(obj.total, 10)   || 0;

        return this;
    } // constructor ()

}
