import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Components
import { PwResetComponent              } from './components/pw-reset/pw-reset.component';
import { DetailsComponent              } from './components/details/details.component';
import { AccountComponent              } from './account.component';
import { RegisterComponent             } from './components/register/register.component';
import { LogoutComponent               } from './components/logout/logout.component';
const routes: Routes = [{
    path: '',
    component: AccountComponent,
    children: [
        { path: 'pw-reset', component: PwResetComponent },
        { path: 'details', component: DetailsComponent },
        { path: 'register', component: RegisterComponent },
        { path: 'logout', component: LogoutComponent }
    ]
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AccountRoutingModule { }
