// This is a flags enum which is easier to maintain with bitwise operations
// instead of the actual integer value.

// tslint:disable: no-bitwise
export enum Privilege {
    None = 0,
    PlaceOrder = 1 << 0,
    AddUser = 1 << 1,
    EditUser = 1 << 2,
    ViewOtherOrders = 1 << 3,
    EditOrders = 1 << 4,
    RefundOrders = 1 << 5,
    AdministerItems = 1 << 6,
    AdministerUsers = 1 << 7,
    AdministerPersonalizedItemLists = 1 << 8,
    AdministerShops = 1 << 9,
    AdministerSiteSettings = 1 << 10,
    AdministerOrders = 1 << 11,
    AdministerDiscounts = 1 << 12,
    ImpersonateUser = 1 << 13,
    ViewEventLog = 1 << 14,
    AdministerRoles = 1 << 15,
    AdministerUserRoles = 1 << 16,
    AdministerPageRoles = 1 << 17,
    AdministerTickets = 1 << 18,
    All = 0x7FFFFFFFFFFFFFFF
}

export class Privileges {

     currentPrivileges: Privilege[] = [];
     privilegeNames: string[]

    // get privileges() { return this.currentPrivileges; }

     set privileges(priviliges: any[]) {
         this.mapPrivileges(priviliges);
     }

    //  set priviliges(priviliges: Privilege[]) {
    //     this.clear();
    //      priviliges.forEach(p => this.currentPrivileges.push(p));
    //  }

     constructor(privileges: string[] = []) {
        this.mapPrivileges(privileges);
         return this;
     }

     private mapPrivileges(privileges: string[]) {
        this.clear();
        if (privileges) {
            this.currentPrivileges = privileges.map(x => Privilege[x]);
         }
     }

     addPriviliges(privileges: string[]) {
         this.mapPrivileges(privileges);
     }

     addPriviliege(privilege: Privilege) {
         this.currentPrivileges.push(privilege);
         this.privilegeNames.push(Privilege[privilege]);
     }

     removePrivilege(privilege: Privilege) {
         this.currentPrivileges = this.currentPrivileges.filter(p => p !== privilege);
     }

     hasPrivilege(privilege: Privilege) {
         return (this.currentPrivileges && !this.currentPrivileges.find(p => p === Privilege.All)
            && !this.currentPrivileges.find(p => p === privilege));
     }

     clear() {
         this.currentPrivileges.length = 0;
     }

    //  get toStringArray() {
    //      const returnValue = [];

    //      this.privileges.forEach(p => {
    //          returnValue.push(p);
    //      });

    //      return returnValue;
    // }

}
