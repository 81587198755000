export class Ticket {

    // Create model then template.
    // template in component TicketComponent which will handle user or order.
    // template needs QR code. Should the QR code be included in the return data or individual trips to the server?
    id: number;
    activated: boolean;
    voided: boolean;
    ticketYearId: number;
    ticketTypeId: number;
    ticketGroupId: number;
    securityCode: string;
    barcode: string;
    orderId: number;
    sku: number;
    // qrCode: string;
    qrCodeSrc: string;
    admissionsUsed: number;
    admissionsLeft: number;
    ticketName: string;
    datesUsed: string;

    constructor(obj: any = null) {
        if (!obj) {
            return this;
        }
        this.id = obj.id;
        this.activated = obj.activated;
        this.voided = obj.voided;
        this.ticketYearId = obj.ticketYearId;
        this.ticketTypeId = obj.ticketTypeId;
        this.ticketGroupId = obj.ticketGroupId;
        this.securityCode = obj.securityCode;
        this.barcode = obj.barcode;
        this.orderId = obj.orderId;
        this.sku = obj.sku;
        this.admissionsUsed = obj.admissionsUsed || 0;
        this.admissionsLeft = obj.admissionsLeft || 0;
        this.qrCodeSrc = 'data:image/png;base64,' + obj.qrCode;
        this.ticketName = obj.ticketName || 'Unknown';
        this.datesUsed = obj.datesUsed || 'Never used';
    }
}
