// Models
import { ProductGroup } from './product-group.model';
// Interfaces
import { IPagination } from 'src/shared/interfaces';


export class ProductGroups implements IPagination {
    items: Array<ProductGroup>;
    // IPagination
    limit: number;
    page: number;
    pages: number;
    total: number;

    constructor(obj: any = {}) {
        this.items = obj.items && obj.items.length
            ? obj.items.map((productGroup) => {
                return new ProductGroup(productGroup);
            })
            : new Array<ProductGroup>();
            // IPagination
        this.limit = obj.limit || 10;
        this.page = obj.page || 1;
        this.pages = obj.pages || 1;
        this.total = obj.total || 0;

        return this;
    } // constructor ()


    getProductGroups() {
        return { message: 'Successfully retrieved product groups', success: true };
    }
}
