import * as  moment from 'moment';

/*
 * Since camping is a product and not an event this will be used for that as well.
 * It is also possible that tickets for events outside of the faire that are held on faire grounds will be sold.
 * Anything like that will be dated as well.
 */
export class ProductDate {
    id: number;

    // To view either the actual dates or a description that is puplated with the dates instead. Logic already exists on the back end for a
    // pre populated description.
    //
    // To Add startDate is required but endDate can be null if it is a single day.
    startDate: string;
    endDate: string;
    description: string;

    // These are required to add a new entry
    active: boolean;
    visibleStartDate: string; // Can be null to always be visible.
    visibleEndDateOffset: number; // Number of days before the end date to stop displaying.

    constructor (obj: any) {
        this.id = obj.id;
        this.startDate = obj.startDate;
        this.endDate = obj.endDate;

        this.description = obj.description;
        this.active = obj.active;
        this.visibleStartDate = obj.visibleStartDate;
        this.visibleEndDateOffset = obj.visibleEndDateOffset;

        return this;
    }
}
