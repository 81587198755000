import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http'; // HttpParams
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { map, first, catchError } from 'rxjs/operators'; // tap, retry
import 'rxjs/add/operator/share';

import * as _ from 'lodash';

// Services
import { RoleService } from './role.service';
import { CartService } from './cart.service';
// Models
import { User, Role, Cart } from 'src/shared/models';

import { environment } from 'src/environments/environment';

const apiPath = environment.apiPrefix + 'api/v1/auth';
// const httpOptions = {
//     headers: new HttpHeaders({ 'Content-Type':  'application/json' })
// };

@Injectable({ providedIn: 'root' })
export class AuthService {
    private currentUserSubject: BehaviorSubject<User>;
    public currentUser: Observable<User>;
    currentCart: Cart;

    constructor(
        private http: HttpClient,
        private roleService: RoleService,
        private cartService: CartService
    ) {
        this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
        this.currentUser = this.currentUserSubject.asObservable();
        this.cartService.currentCart.subscribe(x => this.currentCart = x);
    }


    // Returns: User
    // Return current value of user behavior subject, if exists
    public get currentUserValue(): User {
        return this.currentUserSubject.value;
    }

    // Returns: boolean
    // Check if role(s) passed exists in user.roles
    public userHasRole(user: User, roles: Role[]): boolean {
        if (user.roles && user.roles.length) {
            return roles.filter(role => user.roles.some(r => r.id === role.id)).length > 0;
        } else { return false; }
    }


    login(email: string, password: string) {
        return this.http.post<any>(`${apiPath}/login`, { email, password })
            .pipe(
                map(result => {
                    const user = result.user ? new User(result.user) : null;

                    // Login successful
                    if (user && user.token) {
                        console.log('user : ', user);
                        // Store user details and jwt token in local storage to keep user
                        //  logged in between page refreshes

                        // Check if user has role "super"
                        // -- make more dynamic // check against locked role maybe??
                        if (user.roles && user.roles.length) {
                            let isSuper = false;
                            user.roles.map((role: Role) => {
                                if (role.name === 'Super') { isSuper = true; }
                            });
                            if (isSuper) {
                                this.roleService.getAllRoles(user.token)
                                    .pipe(first())
                                    .subscribe(
                                        data => data,
                                        error => error
                                    );
                            }
                        }

                        localStorage.setItem('currentUser', JSON.stringify(user));
                        this.currentUserSubject.next(user);
                        this.cartService.getCartByUser(user.id);
                    }

                    return user;
                }),
                catchError(this.handleError)
            );
    }

    logout() {
        // remove user from local storage to log user out
        localStorage.removeItem('currentUser');
        this.currentUserSubject.next(null);
        this.cartService.clearCart();
        this.roleService.clearRolesList();
        console.log('currentUserSubject cleared : ', this.currentUserSubject);
        console.log('currentUser : ', this.currentUser);
    }


    registerUser(user: any) {
        return this.http.post<any>(`${apiPath}/register`, user)
            .pipe(
                map(result => {
                    console.log('Registered user : ', result);
                    if (result.user) {
                        localStorage.setItem('currentUser', JSON.stringify(result.user));
                        // localStorage.setItem('token', 'JWT');
                        // localStorage.setItem('token', result['token']);
                        this.currentUserSubject.next(new User(result.user));
                        return result;
                    }
                }),
                catchError(this.handleError)
            );
    }

    sendResetEmail(emailAddress: string) {
        return this.http.post<any>(`${apiPath}/reset`, { emailAddress: emailAddress})
        .pipe(
            map(result => {
                    return result;
            }),
            catchError(this.handleError)
        );
    }

    resetPassword(resetParameters: any) {
        return this.http.put<any>(`${apiPath}/reset`, resetParameters)
        .pipe(
            map(result => {
                return result;
            }),
            catchError(this.handleError)
        );
    }
    
    // isLoginSubject = new BehaviorSubject<boolean>(this.hasToken());

    /*
     * If we have token the user is logged in
     * @returns {boolean}
     */
    // hasToken(): boolean {
    //     return !!localStorage.getItem('token');
    // }


    // getToken() {
    //     return localStorage.getItem('token');
    // }


     // Get cart by user id
    //  getUser(username: string): Observable<User> {
    //     if (!username || username === '') { return null; }
    //     console.log(username);
    //     return this.http.get(`${apiPath}/username/${username}`)
    //     .pipe(
    //         retry(3),
    //         // catchError(this.handleError),
    //         map(res => {
    //             const users = res['data'] ? new Users(res['data']) : new Users();
    //             // This is temporary, it won't work if multiple results (e.g. username: james returns james and jameson)
    //             if (users.users.length === 1) { return new User(users.users[0]); }
    //             else { return new User(); }
    //         })
    //     );
    // }



    // login(email: string, password: string): void {
    //     console.log('called AuthService.login()');
    //     this.http.post<any>(`${apiPath}/login`, { email, password })
    //         .subscribe(
    //             result => {
    //                 console.log(result);
    //                 if (result.data) {
    //                     localStorage.setItem('currentUser', JSON.stringify(result.data));
    //                     // localStorage.setItem('token', 'JWT');
    //                     localStorage.setItem('token', result['token']);
    //                     this.isLoginSubject.next(true);
    //                     return result;
    //                 }
    //             },
    //             error => console.log(error.message)
    //         );
    // }

    // logout() {
    //     // remove user from local storage to log user out
    //     localStorage.removeItem('currentUser');
    //     localStorage.removeItem('token');
    //     this.isLoginSubject.next(false);
    // }


    /**
    *  Login the user then tell all the subscribers about the new status
    */
    // login(username: string, password: string): void {
    //     this.getUser(username)
    //         .subscribe(
    //             user => {
    //                 if (user.id && user.id !== '') {
    //                     console.log(user);
    //                     // localStorage.setItem('token', 'JWT');
    //                     // this.isLoginSubject.next(true);
    //                 } else { console.log('No user found'); }
    //                 return user;
    //             },
    //             error => console.log(error.message)
    //         );
    // }

    /**
    * Log out the user then tell all the subscribers about the new status
    */
    // logout(): void {
    //     localStorage.removeItem('token');
    //     this.isLoginSubject.next(false);
    // }



    // isLoggedIn(): Observable<boolean> {
    //     return this.isLoginSubject.asObservable().share();
    //     // from Angular Authentication Tutorial version
    //     // return !!localStorage.getItem('token')
    // }



  // ERROR HANDLING
    private handleError(error: HttpErrorResponse) {
        console.log('authService > handleError > error : ', error);
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.error(
                `Backend returned code ${error.status}, ` +
                `body was: ${error.error}`
            );
        }
        // return an observable with a user-facing error message
        return throwError(error);
    }



}
