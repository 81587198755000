// Models
import { Order } from './order.model';
import { OrderStatus } from './order-status.model';
import { Discount } from './discount.model';
import { ProductOption } from './product-option.model';
import { EventOccurrence } from './event-occurrence.model';


export class OrderItem {
    id: number;
    order: Order;
    productModel: string;
    product_id: string;
    productItem: any;
    discount: Discount;
    qty: number;
    lineTotal: number;
    status: OrderStatus;

    constructor(obj: any = { }) {
        this.id = obj.id || '';
        this.order = obj.order || null;
        this.productModel = obj.productModel || '';
        this.productItem = null;
        this.discount = new Discount(obj.discount) || null;
        this.qty = obj.qty || 1;
        this.lineTotal = obj.lineTotal || null;
        this.status = new OrderStatus(status) || null;

        // Set this.productItem to either ProductOption or EventOccurrence, depending on which one it is
        if (this.productModel === 'ProductOption') {
            this.productItem = new ProductOption(obj.product) || null;
        } else if (this.productModel === 'EventOccurrence') {
            this.productItem = new EventOccurrence(obj.product) || null;
        }

        return this;
    }
}
