// Angular Core
import { Component, OnInit, OnDestroy, OnChanges, SimpleChanges, SimpleChange, Input, ViewChild } from '@angular/core';
// Forms
import { NgForm, FormControl, Form } from '@angular/forms';

// Services
import { CartService, UserService, AuthService, OrderService } from 'src/shared/services';
// Models
import { Cart, CartItem, CartItemStatus, User, OrderReturn } from 'src/shared/models';
import { Subscription } from 'rxjs';
import { pairwise } from 'rxjs/operators';
import { PaymentCard } from 'src/shared/models/payment-card.model';
import { MatButton } from '@angular/material';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { Personalized } from 'src/shared/models/personalized.model';

@Component({
    selector: 'app-checkout',
    templateUrl: './checkout.component.html',
    styleUrls: ['./checkout.component.scss']
})

export class CheckoutComponent implements OnInit, OnChanges {
    // @Input() cart: Cart;
    // @Output() cartChange = new EventEmitter();


    currentUser = new User();
    cart: Cart;
    changes: SimpleChange;
    orderReturn: OrderReturn;
    isProduction: boolean;

    constructor (
        private authService: AuthService,
        private cartService: CartService,
        private orderService: OrderService,
        private router: Router,
    ) {
        this.authService.currentUser.subscribe(x => this.currentUser = x);
        this.cartService.currentCart.subscribe(x => this.cart = x);
        this.orderService.currentOrderReturn.subscribe(x => this.orderReturn = x);
        console.log(environment);
        this.isProduction = environment.production;
        if (new Date() >= environment.disableDate) {
          this.router.navigate(['/home']);
        }
    }

    showCart = false;
    placingOrder = false;

    paymentCard = new PaymentCard();

    paymentTypes = ['Credit/Debit Card', 'Paypal'];
    months = [
        { name: 'January',      shortName: 'Jan', value: '01',     days: 31 },
        { name: 'February',     shortName: 'Feb', value: '02',     days: 28 },
        { name: 'March',        shortName: 'Mar', value: '03',     days: 31 },
        { name: 'April',        shortName: 'Apr', value: '04',     days: 30 },
        { name: 'May',          shortName: 'May', value: '05',     days: 31 },
        { name: 'June',         shortName: 'Jun', value: '06',     days: 30 },
        { name: 'July',         shortName: 'Jul', value: '07',     days: 31 },
        { name: 'August',       shortName: 'Aug', value: '08',     days: 31 },
        { name: 'September',    shortName: 'Sep', value: '09',     days: 30 },
        { name: 'October',      shortName: 'Oct', value: '10',    days: 31 },
        { name: 'November',     shortName: 'Nov', value: '11',    days: 30 },
        { name: 'December',     shortName: 'Dec', value: '12',    days: 31 }
    ];
    years = [2019, 2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028];

    ngOnInit () {
        if (this.currentUser) {
            this.getCartByUser(this.currentUser.id);
        } else {
            this.cart = new Cart();
        }
    }


    ngOnChanges(changes: SimpleChanges) {
        console.log('ngOnChanges > changes : ', changes);
    }


    getCartByUser(id: number) {
            this.cartService.getCartByUser(id)
                .subscribe(
                    result => this.cart = new Cart(result),
                    error => console.log(error.message)
                );
    }

     updateQty(cartItem: CartItem) {
        console.log(cartItem.qty);

        this.cartService.updateItemQtyInCart(cartItem.cartId, {
            productId: cartItem.productOptionId,
            qty: cartItem.qty
        }).subscribe(result => {
            console.log(result);
        },
        error => console.log(error.message)
        );
    }

    updateName(personalized: Personalized) {
        this.cartService.updatePersonalizedName({
            cartId: this.cart.id,
            personalizedId: personalized.id,
            name: personalized.name
        }).subscribe(result => {
            console.log(result);
        },
        error => console.log(error.message)
        );
    }
    updatePersonalizedItems(cartItem: any) {
        console.log('updatePersonalizedItems() called.');
        if (!cartItem.personalizedType.items || !cartItem.personalizedType.items.length) { cartItem.personalizedType.items = []; }

        const qty = cartItem.qty || 0;
        const items = cartItem && cartItem.hasOwnProperty('personalizedType') && cartItem.personalizedType.items
            ? (cartItem.personalizedType.items || [])
            : [];

        if (qty > items.length) {
            for (let i = 0; i < qty; i++) { items[i] = items.length > i ? items[i] : { name: '' }; }
        } else { items.splice(qty, (items.length - qty)); }

    } // updatePersonalizedItemsLength

    placeOrder() {
        // TODO: Display actual error message.
        if (!this.paymentCard.creditCardNumber || this.paymentCard.creditCardNumber === '') {
            return;
        }
        if (!this.paymentCard.name || this.paymentCard.name === '') {
            return;
        }
        if (!this.paymentCard.expirationMonth || this.paymentCard.expirationMonth === '') {
            return;
        }
        if (!this.paymentCard.expirationYear || this.paymentCard.expirationYear === '') {
            return;
        }
        if (!this.paymentCard.cvd || this.paymentCard.cvd === '') {
            return;
        }

        console.log('Submitting order');
        this.placingOrder = true;
        this.orderService.placeOrder(this.cart.id, this.paymentCard).subscribe(
            data => {
                console.log(data);
                if (this.orderReturn.placed) {
                    this.cartService.clearCart();
                    this.router.navigate(['/cart/order-successful']);
                } else {
                    this.router.navigate(['/cart/order-failed']);
                }
            },
            error => {
                console.error(error)
                this.router.navigate(['/cart/order-failed']);
            },
            () => console.log('huh?')
        );
        console.log('Order submitted');

    }
    addDiscountCode(code: any) {
        // const discountObj = this.availableCartDiscounts.filter((disc) =>
        //     code && code !== ''
        //     && disc.code.toLowerCase() === code.toLowerCase())[0] || null;
        // const discountAlreadyAdded = discountObj && this.cartDiscounts.filter((disc) => disc.id === discountObj.id).length ? true : false;

        // if (discountObj && !discountAlreadyAdded) { this.cartDiscounts.push(discountObj);
        // } else if (discountAlreadyAdded) { console.log('You cannot add a discount multiple times.');
        // } else { console.log(`Discount code: ${code} not found.`); }
        // code = '';
    }

    // removeDiscountCode(discount: any) {
    //     if (this.cartDiscounts.includes(discount)) {
    //         this.cartDiscounts.splice(this.cartDiscounts.indexOf(discount), 1);
    //     }
    // }

    // calculateSubTotal() {
    //     this.cartSubTotal = this.cartItems.reduce((acc, item) => acc + ((item.price - 0) * item.qty), 0);
    //     return this.cartSubTotal;
    // }

    // calculateCartDiscounts() {
    //     this.cartDiscountTotal = this.cartDiscounts.reduce((acc, disc) =>
    //         acc + (disc.amount < 1
    //             ? (this.cartSubTotal * disc.amount)
    //             : disc.amount
    //         ), 0);
    //     return 0 - this.cartDiscountTotal;
    // }

    // calculateCheckoutTotal() {
    //     this.cartTotal =  this.cartSubTotal - this.cartDiscountTotal;

    //     return this.cartTotal;
    // }

}
