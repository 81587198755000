import { environment } from './../../../../environments/environment';
import { Component } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent {
  now: Date;
  salesDisabled: boolean;
  year: number;

  constructor() {
    this.now = new Date();
    this.salesDisabled = this.now > environment.disableDate;
    if (this.now.getMonth() < 8 && this.salesDisabled) {
      this.year = this.now.getFullYear() - 1;
    } else {
      this.year = this.now.getFullYear();
    }
  }
}
