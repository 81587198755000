import { environment } from './../../../environments/environment';
import { Component } from '@angular/core';

// Services
import { AuthService, RoleService, CartService } from 'src/shared/services';
// Models
import { User, Role, Cart } from 'src/shared/models';

@Component({
    selector: 'app-navigation',
    templateUrl: './nav.component.html',
    styleUrls: ['./nav.component.scss']
})

export class NavComponent {
    currentUser: User;
    cart: Cart;
    shopDisabled: boolean;

    constructor(
        private authService: AuthService,
        private roleService: RoleService,
        private cartService: CartService
    ) {
        this.authService.currentUser.subscribe(x => this.currentUser = x);
        this.cartService.currentCart.subscribe(x => this.cart = x);
        console.log('new Date(): ' + new Date());
        console.log('environment.disableDate: ' + environment.disableDate);
        if (new Date() >= environment.disableDate) {
          this.shopDisabled = true;
        }
        console.log('shgopDisabled = ' + this.shopDisabled);
    }

    get role() {
        const roles = {};

        if (this.currentUser && this.currentUser.roles && this.currentUser.roles.length) {
            this.currentUser.roles.map((role) => roles[role.name] = role);
        }

        return roles;
    }

}
