// Interfaces
import { ISelectable } from 'src/shared/interfaces';

export class CartItemStatus implements ISelectable {
    id: number;
    name: string;
    description: string;
    isActiveStatus: boolean;

    // ISelectable
    selected: boolean;

    constructor(obj: any = {}) {
        this.id = obj.id || '';
        this.name = obj.name || '';
        this.description = obj.description || '';
        this.isActiveStatus = obj.isActiveStatus || true;

        this.selected = obj.selected || false;

        return this;
    }

}
