import { environment } from 'src/environments/environment';
import { Component, OnInit } from '@angular/core';
import { NgIf, NgClass } from '@angular/common';
import { timer } from 'rxjs';
import * as moment from 'moment';

// Services
import { ProductGroupService, CartService, AuthService } from 'src/shared/services';
// Models
import {
    ProductGroup, ProductGroups, Product, ProductOption, ProductType, Tag, CartItem, EventOccurrence, Cart
} from 'src/shared/models';
import { Router } from '@angular/router';


@Component({
    selector: 'app-products',
    templateUrl: './products.component.html',
    styleUrls: ['./products.component.scss']
})

export class ProductsComponent implements OnInit {
    // public products: Array<Product>;
    // public testProducts;
    // public events;


    // Need to add "bool featured: true/false
    // Or rather...add a "featured" object, that contains a list of product references, for easier updating/ordering


    constructor(private _productGroupService: ProductGroupService, private _cartService: CartService, private _authService: AuthService, private router: Router) {
        this._cartService.currentCart.subscribe(x => this.cart = x );
        this._authService.currentUser.subscribe(x => this.cart.user = x);
    }

    productGroups = new ProductGroups();
    availableEventDates = [];

    // Placeholder cart id used for testing (user 'harleyquinn''s cart)
    // cartId = '5c14b637990410200cec03b7';
    cart = new Cart();

    ngOnInit() {
        this.getProductGroups();
        if (new Date() >= environment.disableDate) {
          this.router.navigate(['/home']);
        }
    }


    getProductGroups() {
        // Return only <Product> product groups
        this._productGroupService.getProductGroups()
        .subscribe(
            result => {
                this.productGroups = new ProductGroups(result);
                this.productGroups.items.map((productGroup) => {
                    productGroup.products.map((product) => this.productOptionChanged(product));
                });
            },
            error => console.log(error.message),
            () => this.getAvailableEventDates()
        );
    }

    getAvailableEventDates() {
        this.productGroups.items.map((productGroup) => {
            productGroup.products.map((product) => {

                if (product.productModel === 'Event') {
                    product.productOptions.map((productOption) => {
                        const date = moment(productOption.start).format('YYYY-MM-DD');
                        if (productOption.status.isActiveStatus && moment(productOption.start).isSameOrAfter(moment().startOf('day'))) {
                            if (!this.availableEventDates.includes(date)) { this.availableEventDates.push(date); }
                        }
                    });
                }

            });
            this.availableEventDates.sort();
        });
    }


    productOptionChanged(product) {
        product.selectedProductOption = product.productOptions.filter(productOption => productOption.id === parseInt(product.activeOption))[0];
        product.qty = 1;
    }

    addProductToCart(product) {

        this._cartService.addItemToCart({
            // cart: this.cart,
            userId: this.cart.user ? this.cart.user.id : 0,
            cartId: this.cart.id,
            shopId: 1,
            productModel: product.productModel === 'Event' ? 'EventOccurrence' : 'ProductOption',
            productId: product.selectedProductOption.id,
            productDateId: product.selectedProductOption ? product.selectedProductOption.dateId : 0,
            productModifierId: product.selectedProductOption ? product.selectedProductOption.modifierId : 0,
            qty: product.qty || 1
            // status: '5c523300c4e63f0f1c4eb9fa', // cartItemStatusActive
            // lineTotal: product.selectedProductOption.price * 1
        })
        .subscribe(
            result => {
                console.log(result);
            },
            error => console.log(error.message)
        );
    }

    myFilter = (d: Date): boolean => {
        const day = moment(d).format('YYYY-MM-DD');
        // // Prevent Saturday and Sunday from being selected.
        return this.availableEventDates.includes(day);
    }

    getEventDate = (product: Product): Array<ProductOption> => {
        return product.productOptions.filter((productOption) => {
            const eventOccurrence = new EventOccurrence(productOption);
            return moment(eventOccurrence.start).startOf('day').isSame(moment(product['date']).startOf('day'));
        });
    }

}
