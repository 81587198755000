import * as moment from 'moment';

// Models
import { Status } from './status.model';
// Interfaces
import { ISelectable } from 'src/shared/interfaces';


export class Discount implements ISelectable {
    id: number;
    name: string;
    description: string; // The name is the description. "$1 Off Adult Ticket", "20% Off All Tickets" etc..
    status: Status;
    code: string;
    start: moment.Moment; // can be null if no start date (ex: $1 Off)
    end: moment.Moment; // can be null if no start date (ex: $1 Off)
    amount: number;
    isPercentage: boolean;
    appliesToModel: string; // API does not use
    // appliesTo: string;
    appliesTo: Array<any>; // Since potentially applies to non 'products' - this may need to be split into different arrays

    automaticDiscount: boolean; // Only needed on add and update.
    // ISelectable
    selected: boolean;

    // Utility Variables
    // Possibly unneeded since discounts have not been time specific and a discount has always applied from
    // StartDate @ 00:00:00 - End Date @ 23:59:59
    endDate: string;
    endTime: string;
    startDate: string;
    startTime: string;

    constructor(obj: any = { }) {

        this.id = obj.id || '';
        this.name = obj.name || '';
        this.description = obj.description || '';
        this.status = obj.status || new Status();
        this.code = obj.code || '';
        this.start = obj.start ? moment(obj.start) : null;
        this.end = obj.end ? moment(obj.start) : null;
        this.amount = obj.amount || null;
        this.isPercentage = obj.isPercentage || false;
        this.appliesToModel = obj.appliesToModel || null;
        this.appliesTo = obj.appliesTo || null;

        // ISelectable
        this.selected = obj.selected || false;

        // Utility Variables
        this.startDate = this.start !== null ? moment(this.start).format('YYYY-MM-DD') : null;
        this.startTime = this.start !== null ? moment(this.start).format('HH:mm:ss') : null;
        this.endDate = this.end !== null ? moment(this.end).format('YYYY-MM-DD') : null;
        this.endTime = this.end !== null ? moment(this.end).format('HH:mm:ss') : null;

        return this;
    }
}
