import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
// import 'rxjs/add/operator/map';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { map, catchError, tap, retry } from 'rxjs/operators';

import { environment } from 'src/environments/environment';

const apiPath = environment.apiPrefix + 'api/v1';
const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type':  'application/json' })
};

@Injectable({
  providedIn: 'root'
})

export class DataService {

    constructor(private httpClient: HttpClient) { }

    private extractData(res: Response) {
        const body = res;
        return body || { };
    }


  // Get data from the db
  getAPIData(path: string): Observable<any> {
    const url = `${apiPath}${path}`;

    // return this.httpClient.get(url).pipe(
    //     map(this.extractData));
    return this.httpClient.get(url)
        .pipe (
            retry(3), // retry a failed request up to 3 times
            catchError(this.handleError)
        );
        // .subscribe(result => {
        //     console.log('GET Request was successful', result);
        //     return result['data'];
        // }, error => { console.log('Error', error); });

    // return data;
  }


    // Create new item in the db
    postAPIData(path: string, obj: Object): Observable<any> {
        const url = `${apiPath}${path}`;
        // let data = null;

        return this.httpClient.post(url, obj);
        //     .subscribe(result => {
        //         console.log('POST Request is successful', result);
        //         data = result['data'];
        //     }, error => { console.log('Error', error); });

        // return data;
    }

    // Create new item in the db
    putAPIData(path: string, obj: Object): Observable<any> {
        const url = `${apiPath}${path}`;
        // let data = null;

        return this.httpClient.put(url, obj)
            .pipe (
                catchError(this.handleError)
            );
        //     .subscribe(result => {
        //         console.log('PUT Request was successful', result);
        //         data = result['data'];
        //     }, error => { console.log('Error', error); });

        // return data;
    }

    // Delete item from the db
    deleteAPIData(path: string): Observable<any> {
        const url = `${apiPath}${path}`;
        // let data = null;

        return this.httpClient.delete(url)
            .pipe(
                catchError(this.handleError)
            );
        //     .subscribe(result => {
        //         console.log('DELETE Request was successful', result);
        //         data = result;
        //     }, error => { console.log('Error', error); });

        // return data;
    }


    private handleError(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.error(
                `Backend returned code ${error.status}, ` +
                `body was: ${error.error}`
            );
        }
        // return an observable with a user-facing error message
        return throwError('Something bad happened; please try again later.');
    };

    private showLoader(): void {
        console.log('Show loader');
    }

    private hideLoader(): void {
            console.log('Hide loader');
        }

}
