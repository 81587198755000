// Models
import { Discount } from './discount.model';
// Interfaces
import { IPagination } from 'src/shared/interfaces';

export class Discounts implements IPagination {
    discounts: Array<Discount>;
    // IPagination
    limit: number;
    page: number;
    pages: number;
    total: number;

    constructor(obj: any = {}) {
        this.discounts = obj.discounts && obj.discounts.length
            ? obj.discounts.map((discount) => {
                return new Discount(discount);
            })
            : new Array<Discount>();
        // IPagination
        this.limit  = parseInt(obj.limit, 10)   || 10;
        this.page   = parseInt(obj.page, 10)    || 1;
        this.pages  = parseInt(obj.pages, 10)   || 1;
        this.total  = parseInt(obj.total, 10)   || 0;

        return this;
    } // constructor ()

}
