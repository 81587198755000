import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, of, throwError } from 'rxjs';
import 'rxjs/add/operator/catch';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { map, catchError, tap, retry } from 'rxjs/operators';
import { Response, Request } from '@angular/http';

// Models
import { Cart, CartItem } from 'src/shared/models';


// Local Variables
import { environment } from 'src/environments/environment';

const apiPath = environment.apiPrefix + 'api/v1/cart';
const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type':  'application/json' })
};
@Injectable({ providedIn: 'root' })

export class CartService {
    private currentCartSubject: BehaviorSubject<Cart>;
    public currentCart: Observable<Cart>;

    constructor(private _http: HttpClient) {
        this.currentCartSubject = new BehaviorSubject<Cart>(new Cart(JSON.parse(localStorage.getItem('cart'))));
        this.currentCart = this.currentCartSubject.asObservable() as Observable<Cart>;
    }

    // Observable Subject
    // private _cartSubject = new Subject<Cart>();
    // CartItems:  CartItem[] = [];
    // Cart = this._cartSubject.asObservable();


    // addProduct(_product: any) {
    //     console.log('in service');
    //     this.CartItems.push(_product);
    //     this._cartSubject.next(<Cart> { loaded: true, cartItems: this.CartItems });
    // }

    // removeProduct(id: number) {
    //     this.CartItems = this.CartItems.filter((_item) => _item.id !== id);
    //     this._cartSubject.next(<Cart> { loaded: false, cartItems: this.CartItems });
    // }



  // HTTP REQUESTS

    // Get all (or filtered/sorted) users from the server
    // getUsers(users: Users = new Users()): Observable<Users[]> {

    //     const params: HttpParams = new HttpParams()
    //         .set('page', users.page.toString())
    //         .set('limit', users.limit.toString());

    //     return this._http.get(`${apiPath}`, { params: params })
    //         .pipe(
    //             map(res => res['data']),
    //             retry(3), // retry a failed request up to 3 times
    //             catchError(this.handleError)
    //         );
    // }

    // Get single user (by id) from the server
    // getUserById(id): Observable<User> {
    //     if (!id || id === '') { return null; }
    //     return this._http.get(`${apiPath}/id/${id}`)
    //         .pipe(
    //             map(res => {
    //                 console.log(res);
    //                 return res['data'];
    //             }),
    //             retry(3),
    //             catchError(this.handleError)
    //         );
    // }

    setCart(cart: Cart) {
        localStorage.setItem('currentCart', JSON.stringify(cart));
        this.currentCartSubject.next(cart);
    }

    clearCart() {
        localStorage.removeItem('currentCart');
        this.currentCartSubject.next(new Cart());
    }

    // Get cart by email
    getCartByUser(id: number): Observable<Cart> {
        return this._http.get(`${apiPath}/user/${id}`)
        .pipe(
            retry(3),
            catchError(this.handleError),
            map(result => {
                this.setCart(new Cart(result['data']));
                return result['data'];
            })
        );
    }

    // Get cart by user id
    getCartById(id: number): Observable<Cart> {
        if (!id || id <= 0) { return null; }
        return this._http.get(`${apiPath}/${id}`)
        .pipe(
            retry(3),
            catchError(this.handleError),
            map(result => {
                this.setCart(new Cart(result['data']));
                return result['data'];
            })
        );
    }

    // Get cart by user id
    // getCartByUserId(id: string): Observable<Cart> {
    //     if (!id || id === '') { return null; }
    //     return this._http.get(`${apiPath}/user/${id}`)
    //     .pipe(
    //         retry(3),
    //         catchError(this.handleError),
    //         map(res => res['data'])
    //     );
    // }

    getCartByUserId(id: number): Observable<any> {
        if (id <= 0) { return null; }
        return this._http.get(`${apiPath}/user/${id}`)
        .map((res: Response) => res.json())
        .catch((error: any) => Observable.throw('Server error'));
    }

    // Update a cart
    updateCart(cart): Observable<Cart> {
        return this._http.put(`${apiPath}/${cart.id}`, cart)
            .pipe(
                // retry(3),
                catchError(this.handleError),
                map(result => {
                    this.setCart(new Cart(result['data']));
                    return result['data'];
                })
            );
    }

    updatePersonalizedName(personalized): Observable<Cart> {
        return this._http.put(`${apiPath}/items/personalized/${personalized.personalizedId}`, personalized)
        .pipe(
            catchError(this.handleError),
            map(result => { 
                this.setCart(new Cart(result['data']));
                return result['data'];
            })
        );
    }

    // Add an item to a cart
    addItemToCart(cartItem): Observable<Cart> {
        return this._http.post(`${apiPath}/items`, cartItem)
            .pipe(
                catchError(this.handleError),
                map(result => {
                    this.setCart(new Cart(result['data']));
                    return result['data'];
                })
            );
    }

    // Remove an item from a cart
    removeItemFromCart(id: number): Observable<Cart> {
        return this._http.delete(`${apiPath}/items/${id}`)
        .pipe(
            catchError(this.handleError),
            map(result => {
                this.setCart(new Cart(result['data']));
                return result['data'];
            })
        );
    }

    updateItemQtyInCart(cartId: number, data): Observable<Cart> {
            return this._http.put(`${apiPath}/items/${cartId}`, data)
                .pipe(
                    // retry(3),
                    catchError(this.handleError),
                    map(result => {
                        this.setCart(new Cart(result['data']));
                        return result['data'];
                    })
                );
        }


  // ERROR HANDLING
    private handleError(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.error(
                `Backend returned code ${error.status}, ` +
                `body was: ${error.error}`
            );
        }
        // return an observable with a user-facing error message
        return throwError('Something bad happened; please try again later.');
    }

}
