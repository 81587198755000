import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { map, catchError, tap, retry } from 'rxjs/operators';

// Models
import { Events, Event } from 'src/shared/models';

import { environment } from 'src/environments/environment';

const apiPath = environment.apiPrefix + 'api/v1/events';
const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type':  'application/json' })
};

@Injectable({
  providedIn: 'root'
})

export class EventService {

    constructor(private _http: HttpClient) { }


  // HTTP REQUESTS

    // Get event by user id
    getEventById(id: number): Observable<Event> {
        if (!id || id <= 0) { return null; }
        return this._http.get(`${apiPath}/${id}`)
        .pipe(
            retry(3),
            catchError(this.handleError),
            map(res => res['data'])
        );
    }

    // Get data from the db
    getEvents(): Observable<Event[]> {

        return this._http.get(`${apiPath}`)
        .pipe(
            retry(3),
            catchError(this.handleError),
            map(res => res['data'])
        );

        // Example with filter and pagination parameters to include
        // return this.http.get(`${apiPath}`, {
            // params: new HttpParams()
            //     .set('eventId', eventId.toString())
            //     .set('filter', filter)
            //     .set('sortOrder', sortOrder)
            //     .set('page', page.toString())
            //     .set('limit', limit.toString())
        // }).pipe(
        //     map(res => res['data'])
        // );
    }

    // Create new event
    createEvent(event: Event): Observable<Event> {
        return this._http.post(`${apiPath}`, event)
        .pipe(
            catchError(this.handleError),
            map(res => res['data'])
        );
    }

  // ERROR HANDLING
    private handleError(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.error(
                `Backend returned code ${error.status}, ` +
                `body was: ${error.error}`
            );
        }
        // return an observable with a user-facing error message
        return throwError('Something bad happened; please try again later.');
    };

}
