export class OrderReturn {
    placed: boolean;
    orderId: number;
    authNumber: string;
    error: string;

        constructor(obj: any = {}) {
            if (!obj) {
                return this;
            }
            this.placed = obj ? obj.placed : false;
            this.orderId = obj ? obj.orderId : 0;
            this.authNumber = obj ? obj.authNumber :  '';
            this.error = obj ? obj.error : '';

            return this;
        }
}
