// Models
import { Role } from './role.model';
// Interfaces
import { ISelectable } from 'src/shared/interfaces';


export class Page implements ISelectable {
    id: string;
    // Page name (e.g. Admin Products)
    name: string;
    // Page file name/path (e.g. admin.products)
    path: string;
    // Roles allowed access to page
    roles: Array<Role>;

    // ISelectable
    selected: boolean;


    // constructor(obj: any = {}) {
    //     this.id = obj.id || '';
    //     this.name = obj.name || '';
    //     this.description = obj.description || '';
    //     this.members = obj.members && obj.members.length
    //         ? obj.members.map((member: User) => new User(member))
    //         : new Array<User>();
    //     this.locked = !!obj.locked;
    //     this.isAdmin = !!obj.isAdmin;

    //     // ISelectable
    //     this.selected = obj.selected || false;

    //     return this;
    // }
}
