// Models
import { User } from './user.model';
// Interfaces
import { ISelectable } from 'src/shared/interfaces';


export class Role implements ISelectable {
    id: number;
    name: string;
    description: string;
    members: Array<User>;
    locked: boolean;
    isAdmin: boolean;
    // ISelectable
    selected: boolean;
    dbPrivilegeValue: number;


    constructor(obj: any = {}) {
        this.id = obj.id || '';
        this.name = obj.name || '';
        this.description = obj.description || '';
        this.members = obj.members && obj.members.length
            ? obj.members.map((member: User) => new User(member))
            : new Array<User>();
        this.locked = !!obj.locked;
        this.isAdmin = !!obj.isAdmin;

        // ISelectable
        this.selected = obj.selected || false;

        return this;
    }
}
