import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
// import { Observable } from 'rxjs';

// Services
import { AuthService } from 'src/shared/services';
// Models
import { User } from 'src/shared/models';

@Component({
    selector: 'app-auth',
    templateUrl: './auth.component.html',
    styleUrls: ['./auth.component.scss'] // ,
    // providers: [AuthService]
})

export class AuthComponent implements OnInit {
    currentUser: User;
    loginForm: FormGroup;
    loading = false;
    submitted = false;
    returnUrl: string;
    error = '';

    constructor(
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private authService: AuthService
    ) {
        // Redirect to / if already logged in
        // if (this.authService.currentUserValue) {
        //     this.router.navigate(['/']);
        // }
        this.authService.currentUser.subscribe(x => this.currentUser = x);
    }

    ngOnInit() {
        this.loginForm = this.formBuilder.group({
            email: ['', Validators.required],
            password: ['', Validators.required]
        });

        // Get return url from route parameters or default to '/products' - the most likely initial destination.
        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/products';
    }

    // Convenience getter for easy access to form fields
    get f() { return this.loginForm.controls; }

    onSubmit() {
        this.submitted = true;

        // Stop here if form is invalid
        if (this.loginForm.invalid) {
            return;
        }

        this.loading = true;
        this.authService.login(this.f.email.value, this.f.password.value)
            .pipe(first())
            .subscribe(
                data => this.router.navigate([this.returnUrl]),
                error => this.setError(error),
                () => this.loading = false
            );
    }

    setError(error: any) {
        console.log('error : ', error);
        this.error = error;
        this.loading = false;
    }

    logout() {
        this.authService.logout();
        // this.router.navigate(['/account/logout']);
    }


    // isLoggedIn$: Observable<boolean>;

    // constructor(public authService: AuthService) {
    //     this.isLoggedIn$ = authService.isLoggedIn();
    // }

    // ngOnInit() { }

    // login(email: string, password: string) {
    //     console.log(email);
    //     this.authService.login(email, password);
    // }
}
