// Models
import { ReservationType } from './reservation-type.model';
// Interfaces


export class Reservation {
    id: number;
    startDate: Date;
    endDate: Date;
    reservationType: ReservationType = new ReservationType();
    isExtendedCamping: false;
    additionalOptions: Object = {};
    campers: Array<Object> = new Array<Object>();
}
