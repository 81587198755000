import * as moment from 'moment';

// Models
import { Event } from './event.model';
import { Status } from './status.model';
// Interfaces
import { ISelectable } from 'src/shared/interfaces';


export class EventOccurrence implements ISelectable {
    id: number;
    event: string;
    name: string;
    description: string;
    note: string;
    price: number;
    start: moment.Moment;
    end: moment.Moment;
    seatsAvailable: number;
    seatsSold: number;
    status: Status;

    // Utility Variables
    overrideEventDetails: boolean;
    endDate: string;
    endTime: string;
    startDate: string;
    startTime: string;

    // ISelectable
    selected: boolean;


    constructor( eventOccurrence: any = {} ) {

        this.id = eventOccurrence.id || '';
        this.event = eventOccurrence.event || '';
        this.name = eventOccurrence.name || null;
        this.description = eventOccurrence.description || null;
        this.note = eventOccurrence.note || null;
        this.price = eventOccurrence.price || null;
        this.start = eventOccurrence.start ? moment(eventOccurrence.start) : null;
        this.end = eventOccurrence.end ? moment(eventOccurrence.end) : null;
        this.seatsAvailable = eventOccurrence.seatsAvailable || null;
        this.seatsSold = eventOccurrence.seatsSold || null;
        this.status =  new Status(); // new Status(eventOccurrence.status) ||

        // ISelectable
        this.selected = eventOccurrence.selected || false;

        // Utility Variables
        this.overrideEventDetails = false;

        this.startDate = this.start !== null ? moment(this.start).format('YYYY-MM-DD') : null;
        this.startTime = this.start !== null ? moment(this.start).format('HH:mm:ss') : null;
        this.endDate = this.end !== null ? moment(this.end).format('YYYY-MM-DD') : null;
        this.endTime = this.end !== null ? moment(this.end).format('HH:mm:ss') : null;

        return this;
    }
}
