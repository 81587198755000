// Models
import { OrderStatus } from './order-status.model';
// Interfaces
import { IPagination } from 'src/shared/interfaces';

export class OrderStatuses implements IPagination {
    orderStatuses: Array<OrderStatus>;
    // IPagination
    limit: number;
    page: number;
    pages: number;
    total: number;

    constructor(obj: any = {}) {
        this.orderStatuses = obj.orderStatuses && obj.orderStatuses.length
            ? obj.orderStatuses.map((status) => {
                return new OrderStatus(status);
            })
            : new Array<OrderStatus>();
        // IPagination
        this.limit  = parseInt(obj.limit, 10)   || 10;
        this.page   = parseInt(obj.page, 10)    || 1;
        this.pages  = parseInt(obj.pages, 10)   || 1;
        this.total  = parseInt(obj.total, 10)   || 0;

        return this;
    } // constructor ()

}
