// Interfaces
import { ISelectable } from 'src/shared/interfaces';

export class UserStatus implements ISelectable {
    id: number;
    name: string;
    shortName: string;
    description: string;
    isActiveStatus: Boolean;
    // ISelectable
    selected: boolean;

    constructor(obj: any = { }) {
        this.id = obj.id || '';
        this.name = obj.name || '';
        this.shortName = obj.name ? obj.name.split(' ')[0].toLowerCase() : '';
        this.description = obj.description || '';
        this.isActiveStatus = obj.isActiveStatus || false;
        // ISelectable
        this.selected = obj.selected || false;

        return this;
    } // constructor ()

}
