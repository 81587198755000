import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { map, catchError, tap, retry } from 'rxjs/operators';

// Models
import { User, Role, Roles } from 'src/shared/models';



// Local Variables
import { environment } from 'src/environments/environment';

const apiPath = environment.apiPrefix + 'api/v1/roles';
const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type':  'application/json' })
};



@Injectable({ providedIn: 'root' })
export class RoleService implements OnDestroy {
    private rolesListSubject: BehaviorSubject<Role[]>;
    public rolesList: Observable<Role[]>;

    constructor(private http: HttpClient) {
        // this.rolesListSubject = new BehaviorSubject<Role[]>(JSON.parse(localStorage.getItem('rolesList')));
        this.rolesListSubject = new BehaviorSubject<Role[]>(new Array<Role>());
        this.rolesList = this.rolesListSubject.asObservable();
    }

    public get rolesListValue(): Role[] {
        if (!this.rolesListSubject.value.length) {
            // console.log('rolesListSubject doesn\'t have a value. Getting list of roles from api');
            this.getAllRoles()
                .pipe(map(result => {
                    return this.rolesListSubject.value;
                }));
                // .subscribe(
                //     result => {
                //         // console.log('public get rolesListValue() > this.rolesListSubject.value : ', this.rolesListSubject.value);
                //         return this.rolesListSubject.value;
                //     },
                //     error => console.log(error.message)
                // );
        } else {
            // console.log('rolesListSubject has a value already.');
            return this.rolesListSubject.value;
        }
        // console.log('public get rolesListValue() > this.rolesListSubject.value : ', this.rolesListSubject.value);

    }


  // BEHAVIOR SUBJECT
    ngOnDestroy() {
        this.rolesListSubject.unsubscribe();
    }

    // Add authorization/admin requirements to this
    getAllRoles(token: string = null): Observable<Role[]> {
        console.log('getAllRoles() called');

        const params: HttpParams = new HttpParams()
            .set('page', '1')
            .set('limit', '100');

        const authHeaders = token ? new HttpHeaders().set('Authorization', 'Bearer ' + token) : null;

        return this.http.get<Roles>(`${apiPath}`, { headers: authHeaders})
            .pipe(
                map(result => {
                    console.log('result : ', result);
                    const roles: Role[] = result['data'] && result['data'].roles
                        ? result['data'].roles
                        : new Array<Role>();

                    if (roles && roles.length) {
                        // const rolesObj = {};
                        // roles.map((role) => { rolesObj[role.name] = role; });
                        console.log('roles : ', roles);
                        // localStorage.setItem('rolesList', JSON.stringify(roles));
                        this.rolesListSubject.next(roles);
                        console.log(this.rolesListSubject);
                    }
                    return roles;
                }),
                catchError(this.handleError)
            );
    }

    clearRolesList() {
        // localStorage.removeItem('rolesList');
        this.rolesListSubject.next(null);
        console.log('rolesList cleared : this.rolesListSubject : ', this.rolesListSubject);
        console.log('this.rolesList : ', this.rolesList);
    }

  // UTIL
    hasRole(obj: any, role: Role) {
        let roleExists = false;
        [(obj)]['roles'].map((r: Role) => {
            if (r.id === role.id) { roleExists = true; }
        });
        return roleExists;
    }



  // HTTP REQUESTS

    // Get all (or filtered/sorted) roles from the server
    getRoles(roles: Roles = new Roles()): Observable<Roles> {

        const params: HttpParams = new HttpParams()
            .set('page', roles.page.toString())
            .set('limit', roles.limit.toString());

        return this.http.get(`${apiPath}`, { params: params })
            .pipe(
                map(res => res['data']),
                retry(3), // retry a failed request up to 3 times
                catchError(this.handleError)
            );
    }

    // Get single role
    getRoleById(roleId: number, getUsers: boolean = false): Observable<Role> {
        const params: HttpParams = new HttpParams()
            // .set('page', roles.page.toString())
            // .set('limit', roles.limit.toString())
            .set('users', getUsers.toString());

        return this.http.get(`${apiPath}/id/${roleId}`, { params: params })
            .pipe(
                map(res => res['data']),
                retry(3), // retry a failed request up to 3 times
                catchError(this.handleError)
            );
    }

    // Get all roles belonging to a user by user id
    getUserRoles(id: number, roles: Roles = new Roles()): Observable<Roles> {
        const params: HttpParams = new HttpParams()
            .set('page', roles.page.toString())
            .set('limit', roles.limit.toString());

        return this.http.get(`${apiPath}/user/${id}`, { params: params })
            .pipe(
                map(res => res['data']),
                retry(3),
                catchError(this.handleError)
            );
    }

    createRole(role: Role = new Role()): Observable<Role> {
        return this.http.post(`${apiPath}`, role)
            .pipe(
                map(res => res['data']),
                retry(3),
                catchError(this.handleError)
            );
    }

    updateRole(role: Role = new Role()): Observable<Role> {
        return this.http.put(`${apiPath}/${role.id}`, role)
            .pipe(
                map(res => res['data']),
                retry(3),
                catchError(this.handleError)
            );
    }

    // Add a role to a user
    addUserRole(roleId: number, userId: number): Observable<Roles> {
        return this.http.put(`${apiPath}/${roleId}/add/${userId}`, {})
            .pipe(
                map(res => res['data']),
                retry(3),
                catchError(this.handleError)
            );
    }

    // Remove a role from a user
    removeUserRole(roleId: number, userId: number): Observable<Roles> {
        return this.http.put(`${apiPath}/${roleId}/remove/${userId}`, {})
            .pipe(
                map(res => res['data']),
                retry(3),
                catchError(this.handleError)
            );
    }


    deleteRole(roleId: number): Observable<any> {
        return this.http.delete(`${apiPath}/${roleId}`, {})
            .pipe(
                map(res => res),
                retry(3),
                catchError(this.handleError)
            );
    }

    // // Get single user (by id) from the server
    // getUserById(id): Observable<User> {
    //     if (!id || id === '') { return null; }
    //     return this.http.get(`${apiPath}/id/${id}`)
    //         .pipe(
    //             map(res => res['data']),
    //             retry(3),
    //             catchError(this.handleError)
    //         );
    // }

    // // Get user by username
    // getUserByUsername(username): Observable<User[]> {
    //     if (!username || username === '') { return null; }
    //     return this.http.get(`${apiPath}/username/${username}`)
    //     .pipe(
    //         retry(3),
    //         catchError(this.handleError),
    //         map(res => res['data'])
    //     );
    // }

    // // Create new user
    // createUser(user): Observable<User> {
    //     return this.http.post(`${apiPath}`, user)
    //         .pipe(
    //             retry(3),
    //             catchError(this.handleError),
    //             map(res => res['data'])
    //         );
    // }

    // // Update a user
    // updateUser(user): Observable<User> {
    //     return this.http.put(`${apiPath}/${user.id}`, user)
    //         .pipe(
    //             // retry(3),
    //             catchError(this.handleError),
    //             map(res => res['data'])
    //         );
    // }

    // // Delete a user
    // deleteUser(id): Observable<User> {
    //     if (!id || id === '') { return null; }
    //     return this.http.delete(`${apiPath}/${id}`)
    //         .pipe(
    //             retry(3),
    //             catchError(this.handleError),
    //             map(res => res['data'])
    //         );
    // }


  // ERROR HANDLING
    private handleError(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.error(
                `Backend returned code ${error.status}, ` +
                `body was: ${error.error}`
            );
        }
        // return an observable with a user-facing error message
        return throwError('Something bad happened; please try again later.');
    }

}
