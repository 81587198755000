// Models
import { ProductGroup } from './product-group.model';
import { ProductOption } from './product-option.model';
import { Status } from './status.model';
// Interfaces
import { ISelectable } from 'src/shared/interfaces';

enum TaxableStatus {
    Taxable,
    NonTaxable,
    TaxIncluded
}

export class Product implements ISelectable {
    id: string;
    name: string;
    description: string;
    note: string;
    taxableStatus: TaxableStatus; // Update to taxable, non-taxable, or tax-included
    productModel: string;
    productGroup: number;
    productOptions: Array<ProductOption>;
    status: Status;
    activeOption: number;
    // ISelectable
    selected: boolean;

    constructor(obj: any = { }) {
        this.id = obj.id || '';
        this.name = obj.name || '';
        this.description = obj.description || '';
        this.note = obj.note || '';
        // this.taxable = (typeof obj.taxable !== 'undefined') ? !!obj.taxable : true; // !! ?
        this.taxableStatus = obj.taxableStatus;
        this.productModel = obj.productModel || 'Product';
        this.productGroup = obj.productGroup
            ? obj.productGroup.id
            : 0;
        this.productOptions = obj.productOptions && obj.productOptions.length
            ? obj.productOptions.map(productOption => new ProductOption(productOption))
            : new Array<ProductOption>();
        this.status = obj.status || new Status();

        this.activeOption = this.productOptions.length ? this.productOptions[0].id : 0;

        // ISelectable
        this.selected = obj.selected || false;

        return this;
    } // constructor ()

}
