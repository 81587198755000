// Models
import { Cart } from './cart.model';
import { UserStatus } from './user-status.model';
import { Role } from './role.model';
// Interfaces
import { ISelectable } from 'src/shared/interfaces';
import { Privileges } from './privileges.model';


export class User implements ISelectable {
    id: number;
    email: string;
    username: string;
    password: string;
    firstName: string;
    middleInitial: string;
    lastName: string;
    userStatus: UserStatus;
    cart: Cart;
    privileges: Privileges; // = new Privileges();
    roles: Array<Role>;
    token: string;
    // userFlags: Arrays<UserFlag>

    // ISelectable
    selected: boolean;

    constructor(obj: any = {}) {

        this.id = obj.id || '';
        this.email = obj.email || '';
        this.username = obj.username || '';
        this.password = obj.password || '';
        this.firstName = obj.firstName || '';
        this.middleInitial = obj.middleInitial || '';
        this.lastName = obj.lastName || '';

        // User Status
        this.userStatus = new UserStatus(obj.userStatus) || new UserStatus();

        // User Cart
        this.cart = new Cart(obj.cart) || new Cart();

        // User specific privileges
        this.privileges = new Privileges(obj.privileges) || new Privileges();
        // User Roles
        this.roles = obj.roles || [];

        // Token
        this.token = obj.token ? obj.token : null;

        // ISelectable
        this.selected = obj.selected || false;

        return this;
    }
}
