// Models
import { Ticket } from './ticket.model';
// Interfaces
import { IPagination } from 'src/shared/interfaces';

export class Tickets implements IPagination {
    tickets: Array<Ticket>;
    // IPagination
    limit: number;
    page: number;
    pages: number;
    total: number;

    constructor(obj: any = {}) {
        this.tickets = obj.tickets && obj.tickets.length
            ? obj.tickets.map((ticket) => {
                return new Ticket(ticket);
            })
            : new Array<Ticket>();
        // IPagination
        this.limit  = parseInt(obj.limit, 10)   || 10;
        this.page   = parseInt(obj.page, 10)    || 1;
        this.pages  = parseInt(obj.pages, 10)   || 1;
        this.total  = parseInt(obj.total, 10)   || 0;

        return this;
    } // constructor ()

}
