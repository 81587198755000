import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, of, throwError } from 'rxjs';
import 'rxjs/add/operator/catch';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { map, catchError, tap, retry } from 'rxjs/operators';
import { Response, Request } from '@angular/http';

// Models
import { Order, Orders, OrderReturn, Ticket } from 'src/shared/models';
import { PaymentCard } from 'src/shared/models/payment-card.model';


// Local Variables
import { environment } from 'src/environments/environment';
import { Tickets } from '../models/tickets.model';

const apiPath = environment.apiPrefix + 'api/v1/tickets';
const userApiPath = apiPath + '/user';
const orderApiPath = apiPath + '/order';

const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type':  'application/json' })
};
@Injectable({ providedIn: 'root' })

export class TicketService {
    // private currentOrderSubject: BehaviorSubject<Order>;
    // public currentOrder: Observable<Order>;

    // private currentOrderReturnSubject: BehaviorSubject<OrderReturn>;
    // public currentOrderReturn: Observable<OrderReturn>;

    constructor(private _http: HttpClient) {
        // this.currentOrderSubject = new BehaviorSubject<Order>(new Order(JSON.parse(localStorage.getItem('Order'))));
        // this.currentOrder = this.currentOrderSubject.asObservable() as Observable<Order>;

        // this.currentOrderReturnSubject = new BehaviorSubject<OrderReturn>(new OrderReturn());
        // this.currentOrderReturn = this.currentOrderReturnSubject.asObservable() as Observable<OrderReturn>;
    }

    // Get Order by order id
    getAllTicketsByUserId(id: number, currentYear: boolean): Observable<Array<Ticket>> {
        if (!id || id <= 0) { return null; }
        return this._http.get(`${userApiPath}/${id}` + (currentYear ? '/currentYear' : ''))
        .pipe(
            retry(3),
            catchError(this.handleError),
            map(result => {
                return result['data'].map(ticket => new Ticket(ticket)); //new Tickets result['data'];
            })
        );
    }

    getUsableTicketsByUserId(id: number, currentYear: boolean): Observable<Array<Ticket>> {
        if (!id || id <= 0) { return null; }
        return this._http.get(`${userApiPath}/${id}/` + (currentYear ? 'currentYear/' : '') + 'usable')
        .pipe(
            retry(3),
            catchError(this.handleError),
            map(result => {
                return result['data'].map(ticket => new Ticket(ticket)); // return result['data'];
            })
        );
    }

    getNonUsableTicketsByUserId(id: number, currentYear: boolean): Observable<Array<Ticket>> {
        if (!id || id <= 0) { return null; }
        return this._http.get(`${userApiPath}/${id}/` + (currentYear ? 'currentYear/' : '') + 'nonusable')
        .pipe(
            retry(3),
            catchError(this.handleError),
            map(result => {
                return result['data'].map(ticket => new Ticket(ticket)); //return result['data'];
            })
        );
    }

    getAllTicketsByOrderId(id: number): Observable<Array<Ticket>> {
        if (!id || id <= 0) { return null; }
        return this._http.get(`${orderApiPath}/${id}`)
        .pipe(
            retry(3),
            catchError(this.handleError),
            map(result => {
                return result['data'].map(ticket => new Ticket(ticket));
            })
        );
    }

    getUsableTicketsByOrderId(id: number): Observable<Array<Ticket>> {
        if (!id || id <= 0) { return null; }
        return this._http.get(`${orderApiPath}/${id}/usable`)
        .pipe(
            retry(3),
            catchError(this.handleError),
            map(result => {
                return result['data'].map(ticket => new Ticket(ticket));
            })
        );
    }

    getNonUsableTicketsByOrderId(id: number): Observable<Array<Ticket>> {
        if (!id || id <= 0) { return null; }
        return this._http.get(`${orderApiPath}/${id}/nonusable`)
        .pipe(
            retry(3),
            catchError(this.handleError),
            map(result => {
                return result['data'].map(ticket => new Ticket(ticket));
            })
        );
    }
    // getAllTicketsByUserId(id: number): Observable<Tickets> {
    //     if (!id || id <= 0) { return null; }
    //     return this._http.get(`${userApiPath}/${id}`)
    //     .pipe(
    //         retry(3),
    //         catchError(this.handleError),
    //         map(result => {
    //             return result['data'];
    //         })
    //     );
    // }
    // getAllTicketsByUserId(id: number): Observable<Tickets> {
    //     if (!id || id <= 0) { return null; }
    //     return this._http.get(`${userApiPath}/${id}`)
    //     .pipe(
    //         retry(3),
    //         catchError(this.handleError),
    //         map(result => {
    //             return result['data'];
    //         })
    //     );
    // }

    // placeOrder(cartId: number, paymentCard: PaymentCard): Observable<OrderReturn> {
    //     if (!cartId || cartId <= 0) { return null; }
    //     return this._http.post(`${apiPath}/place/${cartId}`, paymentCard)
    //     .pipe(
    //         // retry(3),
    //         catchError(this.handleError),
    //         map(result => {
    //             // this.setOrder(new OrderReturn(result['data']));
    //             this.setOrderReturn(new OrderReturn(result['data']));
    //             return result['data'];
    //         })
    //     );
    // }

    // getOrdersByUserId(id: number): Observable<Orders> {
    //     if (id <= 0) { return null; }
    //     return this._http.get(`${apiPath}/user/${id}`)
    //     .pipe(
    //         catchError(this.handleError),
    //         map( result => {
    //             return new Orders(result['data']);
    //         })
    //     )
    // }

    // // Update a Order
    // updateOrder(Order): Observable<Order> {
    //     return this._http.put(`${apiPath}/${Order.id}`, Order)
    //         .pipe(
    //             // retry(3),
    //             catchError(this.handleError),
    //             map(result => {
    //                 this.setOrder(new Order(result['data']));
    //                 return result['data'];
    //             })
    //         );
    // }

    // // Add an item to a Order
    // addItemToOrder(OrderItem): Observable<Order> {
    //     return this._http.post(`${apiPath}/items`, OrderItem)
    //         .pipe(
    //             catchError(this.handleError),
    //             map(result => {
    //                 this.setOrder(new Order(result['data']));
    //                 return result['data'];
    //             })
    //         );
    // }

  // ERROR HANDLING
    private handleError(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.error(
                `Backend returned code ${error.status}, ` +
                `body was: ${error.error}`
            );
        }
        // return an observable with a user-facing error message
        return throwError('Something bad happened; please try again later.');
    }

}
