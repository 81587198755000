// Models
import { Product } from './product.model';
// Interfaces
import { IPagination } from 'src/shared/interfaces';


export class Products implements IPagination {
    products: Array<Product>;
    // IPagination
    limit: number;
    page: number;
    pages: number;
    total: number;

    constructor(obj: any = {}) {
        this.products = obj.products && obj.products.length
            ? obj.products.map((product) => {
                return new Product(product);
            })
            : new Array<Product>();
        // IPagination
        this.limit  = parseInt(obj.limit, 10)   || 10;
        this.page   = parseInt(obj.page, 10)    || 1;
        this.pages  = parseInt(obj.pages, 10)   || 1;
        this.total  = parseInt(obj.total, 10)   || 0;

        return this;
    }

}
