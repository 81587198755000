import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from 'src/shared/_guards/auth.guard';

// Main Components
import { HomeComponent } from '../app/components/home/home.component';
// import { ProductsComponent } from '../app/components/products/products.component';
import { ProductsComponent } from '../app/components/products/products.component';
import { ContactComponent } from '../app/components/contact/contact.component';
import { PageNotFoundComponent } from '../../shared/components/page-not-found/page-not-found.component';
import { PrivacyPolicyComponent } from '../app/components/privacy-policy/privacy-policy.component';
import { RefundPolicyComponent } from '../app/components/refund-policy/refund-policy.component';
import { HelpComponent } from '../app/components/help/help.component';
import { FofComponent } from '../app/components/fof/fof.component';


// Admin Components
// import { AdminComponent } from 'src/modules/admin/admin.component';
// import { AdminOrdersComponent } from 'src/modules/admin/components/orders/admin.orders.component';
// import { AdminEventsComponent } from 'src/modules/admin/components/events/admin.events.component';
// import { AdminProductsComponent } from 'src/modules/admin/components/products/admin.products.component';
// import { AdminUsersComponent } from 'src/modules/admin/components/users/admin.users.component';
// import { AdminTicketsComponent } from 'src/modules/admin/components/tickets/admin.tickets.component';
// import { AdminShopsComponent } from 'src/modules/admin/components/shops/admin.shops.component';

const Super = 0;
const Admin = 1;
const Entertainer = 2;
const Participant = 3;

const roles = [
    {
        id: '5c7eefec38e4b528147935d4',
        name: 'Super',
        description: 'Super User, someone who has access as well as control to any and all aspects to the application',
        isAdmin: true
    },
    {
        id: '5c7ef02e38e4b528147935d5',
        name: 'Admin',
        description: 'Administrator, someone who has access to and can manage high-level aspects of the application and data',
        isAdmin: true
    },
    {
        id: '5c7ef15438e4b528147935d8',
        name: 'Entertainer',
        description: 'Entertainer represents stage or lane performers, not belonging to festival-employed cast',
        isAdmin: false
    },
    {
        id: '5c7ef08c38e4b528147935d6',
        name: 'Participant',
        description: `A participant is an employee of the festival,
        or a vendor, and requires access to certain participant-level
        sections of the application`,
        isAdmin: false
    }
];

const appRoutes: Routes = [
    { path: 'home', component: HomeComponent },
    { path: '', redirectTo: '/home', pathMatch: 'full' },
    // { path: 'products', component: ProductsComponent, canActivate: [AuthGuard] },
    {   path: 'account',
        loadChildren: 'src/modules/account/account.module#AccountModule'
    },
    { path: 'products', component: ProductsComponent },
    { path: 'fof', component: FofComponent },
    // { path: 'contact', component: ContactComponent, canActivate: [AuthGuard] },
    { path: 'help', component: HelpComponent },
    {
        path: 'cart',
        loadChildren: 'src/modules/cart/cart.module#CartModule'
    },
    {
        path: 'admin',
        loadChildren: 'src/modules/admin/admin.module#AdminModule',
        canActivate: [AuthGuard],
        data: { path: 'admin', roles: [ roles[Super], roles[Admin] ] }
    },
    {
        path: 'participant',
        loadChildren: 'src/modules/participant/participant.module#ParticipantModule'
    },
    {   path: 'refund-policy', component: RefundPolicyComponent },
    {   path: 'privacy-policy', component: PrivacyPolicyComponent},
    {   path: 'orders',
        loadChildren: 'src/modules/orders/orders.module#OrdersModule'
    },
    // { path: 'admin', component: AdminComponent, children: [
    //     { path: 'orders', component: AdminOrdersComponent, outlet: 'admin' },
    //     { path: 'events', component: AdminEventsComponent, outlet: 'admin' },
    //     { path: 'products', component: AdminProductsComponent, outlet: 'admin' },
    //     { path: 'users', component: AdminUsersComponent, outlet: 'admin' },
    //     { path: 'tickets', component: AdminTicketsComponent, outlet: 'admin' },
    //     { path: 'shops', component: AdminShopsComponent, outlet: 'admin' }
        // { path: ':index/orders', component: AdminOrdersComponent, outlet: 'admin' },
        // { path: ':index/events', component: AdminEventsComponent, outlet: 'admin' },
        // { path: ':index/products', component: AdminProductsComponent, outlet: 'admin' },
        // { path: ':index/users', component: AdminUsersComponent, outlet: 'admin' },
        // { path: ':index/tickets', component: AdminTicketsComponent, outlet: 'admin' },
        // { path: ':index/shops', component: AdminShopsComponent, outlet: 'admin' }
    // ]},
  { path: '**', component: PageNotFoundComponent }
];


@NgModule({
    imports: [
      RouterModule.forRoot(appRoutes)
    ],
  exports: [ RouterModule ],
  declarations: [],
  providers: [AuthGuard]
})
export class AppRoutingModule { }
