import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';

// Services
import { AuthService } from 'src/shared/services';
// Classes
import { User } from 'src/shared/models';
import { first } from 'rxjs/operators';

@Component({
    selector: 'app-register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.scss'],
    providers: [AuthService]
})

export class RegisterComponent {
    returnUrl: string;

    constructor( public authService: AuthService, private router: Router) { }

    user: User = new User();
    registered = false;
    error: string;
    disableButtons = false;

    register() {
        this.disableButtons = true;
        this.authService.registerUser(this.user)
            .subscribe(
                result => {
                    this.registered = true;
                    this.authService.login(this.user.email, this.user.password);
                },
                error => this.setError(error)
            );
    }

    cancel() {
        this.router.navigate(['/home']);
    }

    setError(error: any) {
        console.log('error : ', error);
        if (error.error) {
            this.error = error.error;
        } else {
            this.error = error;
        }

        this.disableButtons = false;
    }
}
