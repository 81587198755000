// Models
import { User } from './user.model';
// Interfaces
import { IPagination, ISort } from 'src/shared/interfaces';


export class Users implements IPagination, ISort {
    users: Array<User>;
    // IPagination
    limit: number;
    page: number;
    pages: number;
    total: number;
    // ISort
    sort: string;
    sortDir: string;

    constructor(obj: any = {}) {
        this.users = obj.users && obj.users.length
            ? obj.users.map((user) => {
                return new User(user);
            })
            : new Array<User>();
        // IPagination
        this.limit  = parseInt(obj.limit, 10)   || 10;
        this.page   = parseInt(obj.page, 10)    || 1;
        this.pages  = parseInt(obj.pages, 10)   || 1;
        this.total  = parseInt(obj.total, 10)   || 0;
        // ISort
        this.sort = obj.sort || '';
        this.sortDir = obj.sortDir || 'ASC';

        return this;
    }

}
