export class EventItem {
    id: number;
    eventId: number;
    name: string;
    date: string;
    startTime: string;
    endTime: string;
    seatsTotal: number;
    seatsSold: number;

    constructor(
        id: number = -1,
        eventId: number = -1,
        name: string = '',
        date: string = '',
        // visualStartDate -- When the event actually shows up as an option
        // expirationOffset -- Days before event starts that event disappears from customer view
        startTime: string = '',
        endTime: string = '',
        seatsTotal: number = 0,
        seatsSold: number = 0
    ) {
        this.id = id || -1;
        this.eventId = eventId || -1;
        this.name = name || '';
        this.date = date || '';
        this.startTime = startTime || '';
        this.endTime = endTime || '';
        this.seatsTotal = seatsTotal || 0;
        this.seatsSold = seatsSold || 0;

        return this;
    }
}
