// Models
import { Cart } from './cart.model';
import { CartItemStatus } from './cart-item-status.model';
import { Discount } from './discount.model';
import { Product } from './product.model';
import { ProductOption } from './product-option.model';
import { Event } from './event.model';
import { EventOccurrence } from './event-occurrence.model';
import { BulkPrice } from './bulk-price.model';
import { ProductModifier } from './product-modifier.model';
import { ProductDate } from './product-date.model';
import { Personalized } from './personalized.model';

export class CartItem {
    id: number;
    cartId: number;
    productModel: string; // API does not use and may be unneeded
    product: any;
    productId: number;
    productOption: any;
    productOptionId: number;

    discount: Discount;

    // Date Time, can be null.
    expiresOn: string;
    qty: number;

    productModifier: ProductModifier;
    productDate: ProductDate;

    status: CartItemStatus;

    personalized: Array<Personalized>;

    // API will return this cart item with the following fields set/calculated
    isBulk: boolean; // Optional

    // Regular price (ea.) or  (current) -1 if no price found though the API may return an error condition in the future.
    regularPriceAmount: number;

    // Bulk price (ea.) or -1 if no bulk price is applicable
    bulkPriceAmount: number;

    // Price (ea.) after automatic discount is applied
    discountAmount: number;

    // Sub total without tax
    lineSubTotal: number;

    tax: number;

    // Total (iuncluding tax)
    lineTotal: number;

    constructor(obj: any = { }) {
        this.id = obj.id || '';
        this.cartId = obj.cartId || 0;
        this.productModel = obj.productModel || '';

        this.product = null;
        this.productOption = null;

        this.discount = obj.discount ? new Discount(obj.discount) : null;

        this.discountAmount = obj.discountAmount || 0;
        this.expiresOn = obj.expiresOn;
        this.qty = obj.qty || 1;

        this.productModifier = obj.productModifier ? new ProductModifier(obj.productModifier) : null;
        this.productDate = obj.productDate ? new ProductDate(obj.productDate) : null;

        this.status = new CartItemStatus(obj.status) || new CartItemStatus();

        this.isBulk = obj.isBulk || 0;
        this.regularPriceAmount = obj.regularPriceAmount || -1;
        this.bulkPriceAmount = obj.bulkPriceAmount || -1;
        this.discountAmount = obj.discountAmount || 0;
        this.lineSubTotal = obj.lineSubTotal || 0;
        this.tax = obj.tax || 0;
        this.lineTotal = obj.lineTotal || 0;

       this.personalized = obj.personalized && obj.personalized.length
            ? obj.personalized.map((personalized: Personalized) => new Personalized(personalized))
            : new Array<Personalized>();

        // Set this.productItem to either ProductOption or EventOccurrence, depending on which one it is
        if (this.productModel === 'ProductOption') {
            this.product = new Product(obj.product) || null;
            this.productId = this.product.productId;

            this.productOption = new ProductOption(obj.productOption) || null;
            this.productOptionId = obj.productOptionId;
        } else if (this.productModel === 'EventOccurrence') {
            this.product = new Event(obj.event) || null;
            this.productId = this.product.productId;

            this.productOption = new EventOccurrence(obj.eventOccurrence) || null;
            this.productOptionId = obj.productOptionId;
        }

        return this;
    }
}
