import { ProductOption } from './product-option.model';

export class ProductModifier {
    id: number;
    name: string;
    // After a get this field will be ppulated with what shoulod be displayed.
    // When posting / puting this field should be blank if the display value is going to be the name.
    // This feature is designed for flexibility although currently the display values are the same as the name.
    displayValue: string;

    // A product or product option may not have inventory by itself but a product with a modifier may have limited quantities
    hasInventory: boolean;
    priceModifier: number; // This can be either positive or negative.

    // optional if they are needed
    productOptions: Array<ProductOption>;
    // personalized: Array<Personalized>; //AAnything that requires a name. Currently FOF/SP cards, events (tastings) and camping

    constructor(obj: any = {}) {
        this.id = obj.id || 0;
        this.name = obj.name || '';
        this.displayValue = obj.displayValue || '';
        this.hasInventory = obj.hasInventory || false;
        this.priceModifier = obj.priceModifier || 0;
        this.productOptions =  obj.productOptions && obj.productOptions.length
            ? obj.productOptions.map((productOption: any) => new ProductOption(productOption))
            : new Array<ProductOption>();

            return this;
    }
}
