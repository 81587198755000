import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, of, throwError } from 'rxjs';
import 'rxjs/add/operator/catch';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { map, catchError, tap, retry } from 'rxjs/operators';
import { Response, Request } from '@angular/http';

// Models
import { Cart, CartItem } from 'src/shared/models';


// Local Variables
import { environment } from 'src/environments/environment';

const apiPath = environment.apiPrefix + 'api/v1/payment'; // Change to whatever the payment api path is
const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type':  'application/json' })
};
@Injectable({ providedIn: 'root' })

export class PaymentService {
    private currentCartSubject: BehaviorSubject<Cart>;
    public currentCart: Observable<Cart>;

    constructor(private _http: HttpClient) {
        this.currentCartSubject = new BehaviorSubject<Cart>(new Cart(JSON.parse(localStorage.getItem('cart'))));
        this.currentCart = this.currentCartSubject.asObservable() as Observable<Cart>;
    }



  // HTTP REQUESTS

    // Leaving empty for now til we have more on what to send
    //  Just wanted to create the service so it's at least here



  // ERROR HANDLING
    private handleError(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.error(
                `Backend returned code ${error.status}, ` +
                `body was: ${error.error}`
            );
        }
        // return an observable with a user-facing error message
        return throwError('Something bad happened; please try again later.');
    }

}
