import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { map, catchError, retry } from 'rxjs/operators';

// Models
import { Role, Page } from 'src/shared/models';



// Local Variables
import { environment } from 'src/environments/environment';

const apiPath = environment.apiPrefix + 'api/v1/pages';
const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type':  'application/json' })
};



@Injectable({ providedIn: 'root' })
export class PageService {

    constructor(private http: HttpClient) {}


  // UTIL
    pageHasRole(page: Page, role: Role) {
        let roleExists = false;
        page.roles.map((pageRole: Role) => {
            if (pageRole.id === role.id) { roleExists = true; }
        });
        return roleExists;
    }

    getPageRoles() {
        return [{
            id: '5c7eefec38e4b528147935d4',
            name: 'Super',
            description: 'Super User, someone who has access as well as control to any and all aspects to the application',
            isAdmin: true
        }];
    }



  // HTTP REQUESTS

    // Get all (or filtered/sorted) pages from the server
    getPages() {

        return this.http.get<Page[]>(`${apiPath}`)
            .pipe(
                map(res => res['data']['pages']),
                retry(3), // retry a failed request up to 3 times
                catchError(this.handleError)
            );
    }

    // Get single page
    getPageById(pageId: string) {
        if (!pageId || pageId === '') { return throwError('Id is required'); }

        return this.http.get<Page>(`${apiPath}/id/${pageId}`)
            .pipe(
                map(res => res['data']),
                retry(3), // retry a failed request up to 3 times
                catchError(this.handleError)
            );
    }

    // Get a single page by path
    getPageByPath(path: string) {
        if (!path || path === '') { return throwError('Path is required');
    }
        return this.http.get<Page>(`${apiPath}/path/${path}`)
            .pipe(
                map(res => res['data']),
                // retry(3),
                catchError(this.handleError)
            );
    }

    createPage(page: Page) {
        if (!page.name || page.name === '') { return throwError('Page name is required'); }
        if (!page.path || page.path === '') { return throwError('Page path is required'); }

        return this.http.post<Page>(`${apiPath}`, page)
            .pipe(
                map(res => res['data']),
                // retry(3),
                catchError(this.handleError)
            );
    }

    updatePage(page: Page = new Page()) {
        if (!page.id || page.id === '') { return throwError('Id is required'); }

        return this.http.put<Page>(`${apiPath}/${page.id}`, page)
            .pipe(
                map(res => res['data']),
                retry(3),
                catchError(this.handleError)
            );
    }

    // Add a page to a role
    addPageRole(pageId: string, roleId: string) {
        return this.http.put<Page>(`${apiPath}/${pageId}/add/${roleId}`, {})
            .pipe(
                map(res => res['data']),
                retry(3),
                catchError(this.handleError)
            );
    }

    // Remove a page from a role
    removePageRole(pageId: string, roleId: string) {
        return this.http.put<Page>(`${apiPath}/${pageId}/remove/${roleId}`, {})
            .pipe(
                map(res => res['data']),
                retry(3),
                catchError(this.handleError)
            );
    }


    deletePage(pageId: string) {
        return this.http.delete<Page>(`${apiPath}/${pageId}`, {})
            .pipe(
                map(res => res),
                retry(3),
                catchError(this.handleError)
            );
    }


  // ERROR HANDLING
    private handleError(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.error(
                `Backend returned code ${error.status}, ` +
                `body was: ${error.error}`
            );
        }
        // return an observable with a user-facing error message
        return throwError('Something bad happened; please try again later.');
    }

}
