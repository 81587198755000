import * as moment from 'moment';

// Models
import { CartItem } from './cart-item.model';
import { Discount } from './discount.model';
import { User } from './user.model';
import { Moment } from 'moment';



export class Cart {
    // discounts: Array<Discount>;
    // cartDiscounts: any;

    loaded: boolean;
    id: number;
    user: User;
    shopId: number;
    cartItems: Array<CartItem>;
    expiresOn: string;
    isBulk: boolean;
    anonymousGuid: string;
    discountTotal: number;
    subTotal: number;
    tax: number;
    total: number;
    totalQty: number;

    constructor(obj: any = { }) {
        if (!obj) { obj = {}; }

        this.id = obj.id || 0;
        this.user = obj.user || null;
        this.cartItems = obj.cartItems && obj.cartItems.length
            ? obj.cartItems.map((cartItem: CartItem) => new CartItem(cartItem))
            : new Array<CartItem>();
        this.subTotal = obj.subTotal || 0;
        this.shopId = obj.shopId || 0;
        this.expiresOn = obj.expiresOn || null;
        this.isBulk = obj.isBulk || false;
        this.anonymousGuid = obj.anonymousGuid;
        this.discountTotal = obj.discountTotal || 0;
        this.subTotal = obj.subTotal || 0;
        this.tax = obj.tax || 0;
        this.total = obj.total || 0;
        this.totalQty = obj.totalQty || 0;



        // Discounts
        // this.discounts = obj.discounts || new Array<Discount>();
        // this.cartDiscounts = {
        //     percentage: 0,
        //     dollars: 0
        // };
        // this.discounts.map((discount) => {
        //     if (discount.isPercentage) {
        //         this.cartDiscounts['percentage'] = this.cartDiscounts['percentage'] + (100 / discount.amount);
        //     } else {
        //         this.cartDiscounts['dollars'] = this.cartDiscounts['dollars'] + discount.amount;
        //     }
        // });


        // Total
        // if (obj.cartItems && obj.cartItems.length) {
        //     let totalQty = 0;
        //     this.total = obj.cartItems.reduce((acc, cartItem) => {
        //         // Get whether item is product or event to know which property to reference
        //         // const itemType = cartItem.productModel === 'ProductOption'
        //         //     ? { item: 'product', itemOption: 'productOption' }
        //         //     : { item: 'event', itemOption: 'eventOccurrence' };

        //         // const itemPrice = cartItem[itemType.item][itemType.itemOption].price
        //         //     ? parseFloat(cartItem[itemType.item][itemType.itemOption].price)
        //         //     : parseFloat(cartItem[itemType.item].price) || 0;
        //         // const itemDiscount = cartItem[itemType.item][itemType.itemOption].discount
        //         //     ? cartItem[itemType.item][itemType.itemOption].discount.isPercentage
        //         //         ? itemPrice - (itemPrice * (100 / cartItem[itemType.item][itemType.itemOption].discount.amount))
        //         //         : itemPrice - cartItem[itemType.item][itemType.itemOption].discount.amount
        //         //     : 0;
        //            totalQty += cartItem.qty;
        //         // return parseFloat(acc + (itemPrice - itemDiscount));
        //         return cartItem.total
        //     }, 0);

        //     this.totalQty = totalQty;
        //     // Remove any discounted dollar amount
        //     // this.total = this.total - this.cartDiscounts.dollars;
        //     // Finally, take any discount percentage off of the lowest total remaining
        //     // this.total = this.total - (this.cartDiscounts.percentage * this.total);
        // } else { this.total = 0; }

        return this;
    }

}
