// Models
import { UserStatus } from './user-status.model';
// Interfaces
import { IPagination } from 'src/shared/interfaces';

export class UserStatuses implements IPagination {
    statuses: Array<UserStatus>;
    // IPagination
    limit: number;
    page: number;
    pages: number;
    total: number;

    constructor(obj: any = {}) {
        this.statuses = obj.statuses && obj.statuses.length
            ? obj.statuses.map((status) => {
                return new UserStatus(status);
            })
            : new Array<UserStatus>();
        // IPagination
        this.limit  = parseInt(obj.limit, 10)   || 10;
        this.page   = parseInt(obj.page, 10)    || 1;
        this.pages  = parseInt(obj.pages, 10)   || 1;
        this.total  = parseInt(obj.total, 10)   || 0;

        return this;
    } // constructor ()

}
