import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { map, catchError, tap, retry } from 'rxjs/operators';

// Models
import { Users, User } from 'src/shared/models';


// Local Variables
import { environment } from 'src/environments/environment';

const apiPath = environment.apiPrefix + '/api/v1/users';
const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type':  'application/json' })
};



@Injectable({
  providedIn: 'root'
})

export class UserService {

    constructor(private _http: HttpClient) { }


  // HTTP REQUESTS

    // Get all (or filtered/sorted) users from the server
    getUsers(users: Users = new Users()): Observable<Users[]> {

        const params: HttpParams = new HttpParams()
            .set('page', users.page.toString())
            .set('limit', users.limit.toString());

        return this._http.get(`${apiPath}`, { params: params })
            .pipe(
                map(res => res['data']),
                retry(3), // retry a failed request up to 3 times
                catchError(this.handleError)
            );
    }

    // Get single user (by id) from the server
    getUserById(id): Observable<User> {
        if (!id || id === '') { return null; }
        return this._http.get(`${apiPath}/id/${id}`)
            .pipe(
                map(res => res['data']),
                retry(3),
                catchError(this.handleError)
            );
    }

    // Get user by username
    getUserByUsername(username): Observable<User[]> {
        if (!username || username === '') { return null; }
        return this._http.get(`${apiPath}/username/${username}`)
        .pipe(
            retry(3),
            catchError(this.handleError),
            map(res => res['data'])
        );
    }

    // Create new user
    createUser(user): Observable<User> {
        return this._http.post(`${apiPath}`, user)
            .pipe(
                retry(3),
                catchError(this.handleError),
                map(res => res['data'])
            );
    }

    // Update a user
    updateUser(user): Observable<User> {
        return this._http.put(`${apiPath}/${user.id}`, user)
            .pipe(
                // retry(3),
                catchError(this.handleError),
                map(res => res['data'])
            );
    }

    // Delete a user
    deleteUser(id): Observable<User> {
        if (!id || id === '') { return null; }
        return this._http.delete(`${apiPath}/${id}`)
            .pipe(
                retry(3),
                catchError(this.handleError),
                map(res => res['data'])
            );
    }


  // ERROR HANDLING
    private handleError(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.error(
                `Backend returned code ${error.status}, ` +
                `body was: ${error.error}`
            );
        }
        // return an observable with a user-facing error message
        return throwError('Something bad happened; please try again later.');
    }

}
