// tslint:disable: import-spacing

// Modules
import { NgModule }   from '@angular/core';
// import { BrowserModule }        from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule }          from '@angular/forms';
import { ReactiveFormsModule }    from '@angular/forms';

import { Router }               from '@angular/router';

import { AngularMaterialModule } from '../shared/angular-material.module';

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppComponent }         from './app.component';
import { PageNotFoundComponent } from 'src/shared/components/page-not-found/page-not-found.component';

// import { AdministrationModule } from '../admin/admin.module';
import { CartModule } from '../cart/cart.module';
import { AppRoutingModule }     from '../app-routing/app-routing.module';


// Services
import { AuthService }  from 'src/shared/services/auth.service';
import { AuthGuard }    from 'src/shared/_guards/auth.guard';
import { DataService }  from 'src/shared/services/data.service';

// HTTP_INTERCEPTORS
import { JwtInterceptor }   from 'src/shared/_helpers/jwt.interceptor';
import { ErrorInterceptor } from 'src/shared/_helpers/error.interceptor';

// Shared Components
// import { SpinnerComponent }  from 'src/shared/components/spinner/spinner.component';
import { HeaderComponent }      from 'src/shared/components/header/header.component';
import { NavComponent }         from 'src/shared/components/nav/nav.component';
import { AuthComponent }        from 'src/shared/components/auth/auth.component';
import { FooterComponent }      from 'src/shared/components/footer/footer.component';


// App Components
import { HomeComponent }            from './components/home/home.component';
import { ProductsComponent }    from './components/products/products.component';
import { ContactComponent }         from './components/contact/contact.component';
import { RefundPolicyComponent } from './components/refund-policy/refund-policy.component';
import { PrivacyPolicyComponent } from './components/privacy-policy/privacy-policy.component';
import { HelpComponent } from './components/help/help.component';
import { FofComponent } from './components/fof/fof.component';
import { AccountModule } from '../account/account.module';




@NgModule({
    imports: [
        // BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        ReactiveFormsModule,
        AngularMaterialModule,
        AppRoutingModule,
        HttpClientModule,
        CartModule,
        AccountModule
    ],
    declarations: [
        AppComponent,
        PageNotFoundComponent,
        HeaderComponent,
        NavComponent,
        AuthComponent,
        FooterComponent,
        HomeComponent,
        ProductsComponent,
        ContactComponent,
        RefundPolicyComponent,
        PrivacyPolicyComponent,
        HelpComponent,
        FofComponent
    ],
    providers: [
        AuthService,
        AuthGuard,
        DataService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: JwtInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ErrorInterceptor,
            multi: true
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
