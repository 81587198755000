// [DEPRECATED]

import { Http } from '@angular/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';

@Injectable()
export class ProductsService {

  constructor(private http: Http) {}

  getProducts() {
    return this.http
      .get('/data/products.json')
      .map(response => response.json())
      .toPromise();
  }
}