import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PwResetComponent } from './components/pw-reset/pw-reset.component';
import { DetailsComponent } from './components/details/details.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularMaterialModule } from '../shared/angular-material.module';
import { HttpClientModule } from '@angular/common/http';
import { AccountComponent } from './account.component';
import { RegisterComponent } from './components/register/register.component';
import { AccountRoutingModule } from './account-routing.module';
import { LogoutComponent } from './components/logout/logout.component';

@NgModule({
  imports: [
    FormsModule,
    ReactiveFormsModule,
    AngularMaterialModule,
    HttpClientModule,
    AccountRoutingModule,
  ],
  declarations: [PwResetComponent, DetailsComponent, AccountComponent, RegisterComponent, LogoutComponent]
})
export class AccountModule { }
