import { Component, OnInit } from '@angular/core';

// Services
import { UserService } from 'src/shared/services';

// Models
import { User, Cart } from 'src/shared/models';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {
    title = 'Louisiana Renaissance Festival Ticket System';

    constructor (private userService: UserService) { }

    user = new User();

    ngOnInit () {
        // this.getUser('5bdb6d4cc0999d06f4772c03');
    }

    cartChanged(e) {
        this.user.cart = new Cart(e.cart);
    }

    getUser(id) {
        this.userService.getUserById(id)
            .subscribe(
                result => this.user = new User(result),
                error => console.log(error.message)
            );
    }
}
