import { Component, OnInit } from '@angular/core';
import { OrderReturn } from 'src/shared/models';
import { OrderService } from 'src/shared/services';

@Component({
  selector: 'app-order-failed',
  templateUrl: './order-failed.component.html',
  styleUrls: ['./order-failed.component.scss']
})
export class OrderFailedComponent implements OnInit {
  orderReturn: OrderReturn;

  constructor(private orderService: OrderService) {
    this.orderService.currentOrderReturn.subscribe(x => this.orderReturn  = x);
  }
  ngOnInit() {
  }

}
